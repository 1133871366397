import { useModalContext } from "../../../context/ModalContext";
import { OpenCaseModalContent } from "./OpenCaseModalContent";
import { Button } from "@mui/material";

/**
 * @param provisioner String. Provisioner ID
 * @param provisionerTns List of Strings. TNs associated with this provisioner
 * @param opened Boolean. Whether or not the user has already opened a case against the given provisioner
 * for these numbers on the page already.
 * @returns {Component}
 */
const ErrorProvisionerGroup = ({ provisioner, provisionerTns, opened }) => {
  const { displayModal } = useModalContext();

  const openCaseModal = (provisioner, tns) => {
    const OpenNewCaseModal = (
      <OpenCaseModalContent provisioner={provisioner} tns={tns} />
    );
    displayModal(OpenNewCaseModal, "Open a New Case", { width: "50%" }, false);
  };

  return (
    <>
      <div className="results-prov-group">
        <div className="prov-name">{provisioner}</div>
        <div className="prov-tn-list">
          {provisionerTns.map((tn) => {
            return (
              <li className="tn" key={tn}>
                {tn}
              </li>
            );
          })}
        </div>
        <div className="open-case-button">
          <Button
            variant="outlined"
            disabled={opened}
            onClick={() => {
              if (!opened) {
                openCaseModal(provisioner, provisionerTns);
              }
            }}
          >
            {!opened ? "Open Case" : "Case Opened"}
          </Button>
        </div>
      </div>
    </>
  );
};

export { ErrorProvisionerGroup };
