import { ReactComponent as LoadingSVG } from "./loading.svg";
import { useSelector } from "react-redux";

const LoadingDisplay = () => {
  const caseListLoading = useSelector((state) => state.conflictCase.isLoading);
  const notificationListLoading = useSelector(
    (state) => state.notification.isLoading
  );
  const authLoading = useSelector((state) => state.auth.isLoading);

  return (
    <>
      {(caseListLoading || notificationListLoading || authLoading) && (
        <div className="loading-icon-container">
          <LoadingSVG />
        </div>
      )}
    </>
  );
};

export default LoadingDisplay;
