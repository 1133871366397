import useIsAdminAuthenticated from "../hooks/useIsAdminAuthenticated";
import useIsUserAuthenticated from "../hooks/useIsUserAuthenticated";
import { authActions } from "../store/authSlice";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import logger from "../utils/logger";
import { authResultIsAdmin } from "../utils/authUtils";
import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router";
import userManager, { loadUserFromStorage } from "./userServices";
import { useSelector, useDispatch } from "react-redux";
import { errorActions } from "../store/errorSlice";

const AutoSignin = ({ store }) => {
  const isAdminAuthenticated = useIsAdminAuthenticated();
  const isUserAuthenticated = useIsUserAuthenticated();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let urlPath = window.location.href.substring(window.location.origin.length);
  let isLoginScreen = urlPath === "/";
  let isCallbackScreen = urlPath.includes("/callback");

  useEffect(() => {
    const signinAsync = async () => {
      logger.log("AutoSignin");
      // Only execute if we don't have any user information inside our store
      let user;
      try {
        //First, try and load from local storage
        logger.log("AutoSignin: Attempting retrieving data from local storage");
        user = await loadUserFromStorage(store);
        //If that works, yay, they're authenticated
        logger.log("AutoSignin: Retrieving data from local storage success");
        dispatch(conflictCaseActions.setIsLoading(false));
        let isAdmin =
          user.profile.custom_attributes[0]["userRole"] ===
          "Operations Engineer";
        if (isAdmin) {
          navigate("/admin/caselist");
        }
      } catch (err) {
        logger.log("AutoSignin: Attempt signin silent");
        userManager
          .signinSilent()
          .then((res) => {
            logger.log("AutoSignin: Attempt signin silent succeded", res);
            let isAdmin = authResultIsAdmin(res);
            // If they're on the default login page, foward them to the caselist page.
            // If not, just let them continue to whatever page they wanted.
            if (urlPath === "" || urlPath === "/") {
              if (isAdmin) {
                navigate("/admin/caselist");
              } else {
                navigate("/caselist");
              }
            }

            //redirect any non-admin accessing an admin route back to caselist
            if (!isAdmin && urlPath.indexOf("admin") > -1) {
              navigate("/caselist");
            }

            //redirect any admin accessing a non-admin route back to caselist
            if (isAdmin && !urlPath.indexOf("admin") > -1) {
              navigate("/admin/caselist");
            }

            dispatch(conflictCaseActions.setIsLoading(false));
          })
          .catch((err) => {
            if (!isLoginScreen) {
              dispatch(
                errorActions.setError({
                  errorMessage:
                    "Sorry, appears like you will have to re-log in.",
                  errorTs: 0,
                })
              );
            }
            logger.log("AutoSignin: Attempt signin silent failed");
            console.error(err);
            navigate("/");
            dispatch(conflictCaseActions.setIsLoading(false));
          });
      }
    };

    if (!isAdminAuthenticated() && !isUserAuthenticated()) {
      if (!isLoginScreen) dispatch(conflictCaseActions.setIsLoading(true));
      if (!isCallbackScreen) signinAsync();
    }
  }, []);

  return <></>;
};

export default AutoSignin;
