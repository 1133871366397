import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  initialSyncAdminMiddleware,
  initialSyncAsProvisionerAdminMiddleware,
} from "../middleware/adminMiddleware";
import { Outlet } from "react-router";

const InitialAdminSync = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const currentPid = useSelector((state) => state.admin.currentPid);
  const alreadySelectedPid = currentPid
    ? currentPid.value
      ? true
      : false
    : false;

  useEffect(() => {
    dispatch(initialSyncAdminMiddleware());
  }, [isAdmin]);

  useEffect(() => {
    dispatch(initialSyncAsProvisionerAdminMiddleware());
  }, [alreadySelectedPid, isAdmin]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default InitialAdminSync;
