import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { initialSyncMiddleware } from "../middleware/conflictCaseMiddleware";
import { Outlet } from "react-router";

const InitialSync = () => {
  const dispatch = useDispatch();
  const { pid, email } = useSelector((state) => state.auth);
  const userDataLoadedFromStorage = pid !== null || email !== null;

  useEffect(() => {
    if (userDataLoadedFromStorage) {
      dispatch(initialSyncMiddleware());
    }
  }, [userDataLoadedFromStorage]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default InitialSync;
