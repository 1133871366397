import { useSelector, useDispatch } from "react-redux";
import { errorActions } from "./store/errorSlice";
import SideBar from "./pages/SideBar";
import TopBar from "./pages/TopBar";
import AlertModal from "./components/ui/AlertModal";
import AutoSignin from "./auth/AutoSignin";
import LoadingDisplay from "./LoadingDisplay";
import { Outlet } from "react-router";

const SharedLayout = ({ store }) => {
  const { error } = useSelector((state) => state.error);
  const dispatch = useDispatch();

  return (
    <>
      <TopBar />
      <SideBar />
      <LoadingDisplay />
      <AutoSignin store={store} />
      <AlertModal
        error={error}
        closeModal={() => dispatch(errorActions.hideError())}
        clearError={() => dispatch(errorActions.clearError())}
      />
      <div className="main-content-container">
        <Outlet />
      </div>
    </>
  );
};

export default SharedLayout;
