export function setError(state, action) {
  state.error = { ...state.error, ...action.payload };
  state.error.isError = true;
}
export function hideError(state, action) {
  state.error.isError = false;
}
export function clearError(state, action) {
  state = {
    isError: false,
    errorMessage: "",
    errorTs: 10000,
    isError: null,
  };
}
