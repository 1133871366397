import { useEffect, useRef } from "react";
import { authActions } from "../store/authSlice";
import { setAuthHeader } from "../service/axios";
import React from "react";
import logger from "../utils/logger";

export default function AuthProvider({
  userManager: manager,
  store,
  children,
}) {
  let userManager = useRef();
  //let { displayAlertModal } = useModalContext();

  useEffect(() => {
    userManager.current = manager;

    // Only triggered on initial sign in, and token refresh
    const onUserLoaded = (user) => {
      logger.log("AuthProvider/onUserLoaded executed");
      let currTime = Math.floor(new Date().getTime() / 1000);
      user.expires_at = currTime + 3 * 60;

      userManager.current.storeUser(user);
      logger.log(user);
      user = JSON.parse(JSON.stringify(user));
      store.dispatch(authActions.storeUser(user));
    };

    const onUserUnloaded = () => {
      setAuthHeader(null);
      logger.log(`user unloaded`);
    };

    const onUserSignedOut = () => {
      logger.log(`user signed out`);
    };

    const onSilentRenewFailure = () => {
      logger.log("user silent renew failure");
      userManager.current.signinRedirect();
    };

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded);
    userManager.current.events.addUserUnloaded(onUserUnloaded);
    userManager.current.events.addUserSignedOut(onUserSignedOut);
    userManager.current.events.addSilentRenewError(onSilentRenewFailure);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.current.events.removeUserLoaded(onUserLoaded);
      userManager.current.events.removeUserUnloaded(onUserUnloaded);
      userManager.current.events.removeUserSignedOut(onUserSignedOut);
      userManager.current.events.removeSilentRenewError(onSilentRenewFailure);
    };
  }, [manager, store]);

  return React.Children.only(children);
}
