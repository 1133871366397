import { useSelector } from "react-redux";
import { capitalize } from "../../utils/genericUtils";
import { getTimeString } from "../../utils/timeUtils";
import { NNID_TYPE_MAPPING } from "../../utils/nnidTypeEnums";

/**
 * Static Header information of a case.
 *
 * @param {object} currentCase currentCase object with associated fields
 * @returns
 */
const CaseHeader = ({ currentCase }) => {
  const pid = useSelector((state) => state.auth.pid);

  return (
    <div className="section case-header">
      <div className="header-item">
        <span className="bold"> Opened By: </span>
        {currentCase.openedBy === pid
          ? "You"
          : capitalize(currentCase.openedBy)}
      </div>
      <div className="header-item">
        <span className="bold"> Opened Against: </span>
        {currentCase.openedAgainst === pid
          ? "You"
          : capitalize(currentCase.openedAgainst)}
      </div>
      <div className="header-item">
        <span className="bold">Status: </span>
        {capitalize(currentCase.status)}
      </div>
      <div className="header-item">
        <span className="bold">Number of TNs:</span> {currentCase.numberTns}
      </div>
      <div className="header-item">
        <span className="bold">Opened On: </span>
        {getTimeString(currentCase.openedTs)}
      </div>
      <div className="header-item">
        <span className="bold">Expires On: </span>
        {getTimeString(currentCase.expiresTs)}
      </div>
      <div className="header-item">
        <span className="bold">Requesting NNID: </span>
        {currentCase.nnidToProvisionTo}
      </div>
      <div className="header-item">
        <span className="bold">Requesting NNID Type: </span>
        {NNID_TYPE_MAPPING[currentCase.nnidType]}
      </div>
    </div>
  );
};

export { CaseHeader };
