import { useEffect } from "react";
import userManager, { signinRedirectCallback } from "../../auth/userServices";
import { useNavigate } from "react-router-dom";

import logger from "../../utils/logger";
import { authResultIsAdmin } from "../../utils/authUtils";

const Callback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function signinAsync() {
      logger.log("Inside TestCallback!");
      await signinRedirectCallback()
        .then((res) => {
          logger.log("Test Callback success!!", res);
          if (authResultIsAdmin(res)) {
            navigate("/admin/caselist");
          } else {
            navigate("/caselist");
          }
        })
        .catch((err) => {
          console.error("Test Callback Error!!", err);
          //navigate("/caselist");
        });
    }
    signinAsync();
  }, []);

  return <div>Redirecting...</div>;
};

export default Callback;
