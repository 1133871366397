import { errorActions } from "../store/errorSlice";
import { signinRedirect } from "../auth/userServices";
import logger from "../utils/logger";

const UNAUTHORIZED_REDIRECT_PAUSE_TS = 3000;
const NETWORK_ERROR_CODE = "ERR_NETWORK";
const NETWORK_ERROR_ERROR_MESSAGE =
  "Sorry, you are currently unable to reach our servers. Please try again later";

export function handleRequestError(dispatch, err) {
  logger.error(err);

  if (err.code && err.code === NETWORK_ERROR_CODE) {
    dispatch(
      errorActions.setError({
        errorMessage: NETWORK_ERROR_ERROR_MESSAGE,
        errorTs: 0,
      })
    );
    return;
  }

  let errorData = err.response.data;
  let errorStatusCode = err.response.status;
  switch (errorStatusCode) {
    case 500: //INTERNAL_SERVER_ERROR
      dispatch(
        errorActions.setError({
          errorMessage: errorData.errorMessage,
          errorTs: errorData.errorTs,
        })
      );
      break;
    case 400: //BAD_REQUEST
      dispatch(
        errorActions.setError({
          errorMessage: errorData.errorMessage,
          errorTs: errorData.errorTs,
        })
      );
      break;
    case 401: //UNAUTHORIZED
      dispatch(
        errorActions.setError({
          errorMessage: errorData.errorMessage,
          errorTs: 0,
        })
      );
      //Redirect them in 3 seconds
      setTimeout(() => {
        signinRedirect();
      }, UNAUTHORIZED_REDIRECT_PAUSE_TS);
      break;
  }
}
