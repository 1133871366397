const USER_ROLE_ATTRIBUTE_NAME = "userRole";
const USER_PID_ATTRIBUTE_NAME = "provisionerId";

export function authResultIsAdmin(res) {
  if (!res.profile.custom_attributes) return false;
  if (!res.profile.custom_attributes[0]) return false;
  if (!res.profile.custom_attributes[0][USER_ROLE_ATTRIBUTE_NAME]) return false;

  return (
    res.profile.custom_attributes[0][USER_ROLE_ATTRIBUTE_NAME] ===
    "Operations Engineer"
  );
}

export function authResultGetPid(res) {
  if (!res.profile.custom_attributes) return null;
  if (!res.profile.custom_attributes[0]) return null;
  if (!res.profile.custom_attributes[0][USER_PID_ATTRIBUTE_NAME]) return null;

  return res.profile.custom_attributes[0][USER_PID_ATTRIBUTE_NAME];
}
