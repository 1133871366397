const getMonths = () => {
  return [
    { display: "Jan", value: 0 },
    { display: "Feb", value: 1 },
    { display: "Mar", value: 2 },
    { display: "Apr", value: 3 },
    { display: "May", value: 4 },
    { display: "Jun", value: 5 },
    { display: "Jul", value: 6 },
    { display: "Aug", value: 7 },
    { display: "Sep", value: 8 },
    { display: "Oct", value: 9 },
    { display: "Nov", value: 10 },
    { display: "Dec", value: 11 },
  ];
};

/**
 * Returns a list of objects that represent the possible days given that specific month and year.
 * Ex: Febuary 2021 has 28 days, however February 2020 has 29.
 *
 * @param {Integer} year The year
 * @param {Integer} month The month, starting from index 0. (aka, Jan is 0, Mar is 2...)
 * @returns {List:Objects} List of objects both with a "display" and "value" key. However, both
 * display and value will be the integer representation of that specific day.
 */
const getDaysInMonth = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate();

  let daysList = [];

  for (let i = 1; i <= daysInMonth; i++) {
    daysList.push({
      display: i,
      value: i,
    });
  }

  return daysList;
};

export { getMonths, getDaysInMonth };
