import { useRef } from "react";
import { useModalContext } from "../../context/ModalContext";
import { useDispatch } from "react-redux";
import { submitRejectTnsMiddleware } from "../../middleware/conflictCaseMiddleware";
import { Button } from "@mui/material";

/**
 * Content of the modal displayed after user selects numbers and selects the reject button.
 *
 * @param {list} tns List of strings of TNs about to be rejected.
 * @param {String} provisioner String of provisioner name to be displayed on this modal
 * @param {function} closeModal function to close the modal
 * @param {function} Accept function to occur after the user has rejected the Tns
 * @returns {Component}
 */
const RejectTnsModalContent = ({
  tns,
  provisioner,
  caseId,
  setSelectedTns,
}) => {
  const { closeModal } = useModalContext();
  const dropDownRef = useRef(null);
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();

  /**
   *
   * @param {List: String} tns String list of TNs which the approve/reject modal was affecting
   * @param {String} reason The reason from the dropdown the user selected.
   * @param {String} additionalText any additional text you wish to be added to the system comment that states this action occured.
   */
  const respondTnsRejectSubmit = (tns, reason, additionalContext) => {
    dispatch(
      submitRejectTnsMiddleware(
        provisioner,
        caseId,
        tns,
        reason,
        additionalContext
      )
    );

    setSelectedTns([]);
    closeModal();
  };

  if (tns.length === 0) {
    return (
      <p>
        You did not selected any TNs! Please select one or more and try again.
      </p>
    );
  }
  return (
    <>
      <p>
        You have selected to REJECT the following numbers requested by{" "}
        {provisioner}:
      </p>
      <div className="tn-accept-reject-container">
        {tns.map((tn) => {
          return <li className="tn">{tn}</li>;
        })}
      </div>
      <p>Please select a reason why:</p>
      <select ref={dropDownRef}>
        <option>Unsatisfactory LOA</option>
        <option>LOA not included</option>
        <option>Unwilling to release numbers at this time</option>
        <option>Other (Please describe below)</option>
      </select>
      <p>(Opt) Please give more context for your decision</p>
      <textarea ref={textAreaRef} />
      <div className="accept-tns-control-container">
        <Button
          className="danger"
          variant="contained"
          color="error"
          onClick={() =>
            respondTnsRejectSubmit(
              tns,
              dropDownRef.current.value,
              textAreaRef.current.value.length > 1
                ? textAreaRef.current.value
                : null
            )
          }
        >
          Reject
        </Button>
        <Button
          variant="contained"
          color="warning"
          className="warning"
          onClick={closeModal}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export { RejectTnsModalContent };
