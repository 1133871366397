import { configureStore } from "@reduxjs/toolkit";
import { conflictCaseSlice } from "./conflictCasesSlice";
import { notificationSlice } from "./notificationsSlice";
import { authSlice } from "./authSlice";
import { applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { adminSlice } from "./adminSlice";
import { errorSlice } from "./errorSlice";

const store = configureStore(
  {
    reducer: {
      notification: notificationSlice.reducer,
      conflictCase: conflictCaseSlice.reducer,
      auth: authSlice.reducer,
      admin: adminSlice.reducer,
      error: errorSlice.reducer,
    },
  },
  applyMiddleware(thunkMiddleware)
);

export default store;
