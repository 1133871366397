import {
  FIELD_VALUES,
  OPERATOR_VALUES,
  FILTER_FUNCTIONS,
} from "./filterStaticValues";
import { useState } from "react";
import DropDown from "../../components/forms/DropDown";
import DateInput from "../../components/forms/DateInput";
import TextboxInput from "../../components/forms/TextboxInput";
import { parseDropdownDataOptions } from "./parseDropdownDataOptions";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import { Button } from "@mui/material";

/**
 * Component that corresponds to the create new filter modal. Will create filter objects
 * and pass them back up to the parent component, where the logic should occur there.
 *
 * @param {Method} closeFilterDropdown method to close the filter modal
 * @returns {Component}
 */
const FilterModal = ({ closeFilterDropdown }) => {
  const NO_ERROR_STATE = {
    fieldError: false,
    fieldErrorMessage: "",
    operatorError: false,
    operatorErrorMessage: "",
    valueError: false,
    valueErrorMessage: "",
  };

  const allCasesForUser = useSelector((state) => state.conflictCase.caseList);
  const [fieldSelect, setFieldSelect] = useState(null);
  const [operatorSelect, setOperatorSelect] = useState(null);
  const [valueSelect, setValueSelect] = useState(null);
  const [error, setError] = useState(NO_ERROR_STATE);

  const dispatch = useDispatch();
  const pid = useSelector((state) => state.auth.pid);

  const dropdownValueOptions = useMemo(() => {
    return parseDropdownDataOptions(allCasesForUser, pid);
  }, [allCasesForUser]);

  const addNewFilter = (filterObj) => {
    dispatch(conflictCaseActions.addNewFilter(filterObj));
  };

  const validateInputsAndApply = () => {
    let isError = false;
    let newErrorState = {};
    if (!fieldSelect) {
      newErrorState.fieldError = true;
      newErrorState.fieldErrorMessage = "You did not choose a field!";
      isError = true;
    }
    if (!operatorSelect) {
      newErrorState.operatorError = true;
      newErrorState.operatorErrorMessage = "You did not choose a field!";
      isError = true;
    }
    if (!valueSelect) {
      newErrorState.valueError = true;
      newErrorState.valueErrorMessage = "You did not choose a field!";
      isError = true;
    }
    if (isError) {
      setError({
        ...NO_ERROR_STATE,
        ...newErrorState,
      });
    } else {
      setError(NO_ERROR_STATE);
      addNewFilter({
        field: fieldSelect.value,
        fieldDisplay: fieldSelect.display,
        operator: operatorSelect.value,
        operatorDisplay: operatorSelect.display,
        value: valueSelect.value,
        valueDisplay: valueSelect.display,
      });
      closeFilterDropdown();
    }
  };

  return (
    <div className="filters-modal">
      <h2>Filters:</h2>
      <div className="line"></div>
      <div className="field-operator-container">
        <div className="field-container">
          <h3>Field</h3>
          {error.fieldError && (
            <div className="input-error">{error.fieldErrorMessage}</div>
          )}
          <DropDown
            placeholder="Select a field"
            list={FIELD_VALUES}
            onClick={(field) => setFieldSelect(field)}
            error={error.fieldError}
          />
        </div>
        <div className="operator-container">
          <h3>Operator</h3>
          {error.operatorError && (
            <div className="input-error">{error.operatorErrorMessage}</div>
          )}
          <DropDown
            placeholder="Select an operator"
            disabled={fieldSelect === null}
            list={fieldSelect ? OPERATOR_VALUES[fieldSelect.value] : []}
            onClick={(operator) => setOperatorSelect(operator)}
            error={error.operatorError}
          />
        </div>
      </div>

      <div className="line" />
      <div className="value-select">
        <h3>Value</h3>
        {error.valueError && (
          <div className="input-error">{error.valueErrorMessage}</div>
        )}
        {fieldSelect && fieldSelect.typeDisplay === "dropdown" && (
          <DropDown
            placeholder="Select a value"
            disabled={!fieldSelect || !operatorSelect}
            error={error.valueError}
            list={fieldSelect ? dropdownValueOptions[fieldSelect.value] : []}
            onClick={(value) => setValueSelect(value)}
          />
        )}
        {fieldSelect && fieldSelect.typeDisplay === "text-box" && (
          <TextboxInput
            onChange={(e) =>
              setValueSelect({
                value: e.target.value,
                display: e.target.value,
              })
            }
            type="number"
            label={"Select a Value"}
          />
        )}
        {fieldSelect && fieldSelect.typeDisplay === "date" && (
          <DateInput
            onChange={(date) =>
              setValueSelect({
                value: date.value,
                display: date.display,
              })
            }
          />
        )}
      </div>
      <div className="filter-controls">
        <Button variant="contained" onClick={() => validateInputsAndApply()}>
          Apply
        </Button>
        <Button variant="outlined" onClick={closeFilterDropdown}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default FilterModal;
