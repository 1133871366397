import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Error from "../Error/Error";
import { SlOptionsVertical } from "react-icons/sl";
import { fetchCaseMiddleware } from "../../middleware/conflictCaseMiddleware";
import { CaseHeader } from "./CaseHeader";
import { Loas } from "./Loas";
import { RequestedTns } from "./RequestedTns";
import { Activity } from "./Activity";
import { closeUnfinishedCaseMiddleware } from "../../middleware/conflictCaseMiddleware";
import { caseOpened } from "../../utils/genericUtils";
import { useModalContext } from "../../context/ModalContext";
import { Popover, Box, Fab } from "@mui/material";

/**
 * Component of Singular Case page.
 *
 * @returns Component
 */
const SingularCase = () => {
  const { caseId } = useParams();
  const [error, setError] = useState(false);
  const optionsButtonRef = useRef(null);
  const pid = useSelector((state) => state.auth.pid);
  const { displayModal } = useModalContext();
  const [anchorEl, setAnchorEl] = useState(null);

  let currentCase = useSelector((state) =>
    state.conflictCase.caseList.find(
      (singularCase) => singularCase.caseId === caseId
    )
  );

  const isCaseCloseable =
    currentCase && currentCase.openedBy === pid && caseOpened(currentCase);
  const dispatch = useDispatch();

  /**
   * Fetches the case, for any updates or if they enter directly onto this page
   */
  useEffect(() => {
    if (caseId === null) {
      setError(true);
    }
    dispatch(fetchCaseMiddleware(caseId));
  }, []);

  const closeCase = () => {
    handleActionMenuDropdownClose();
    if (isCaseCloseable) {
      dispatch(closeUnfinishedCaseMiddleware(caseId));
    } else {
      displayModal(
        <p>
          Sorry, you may only close a case if you are the{" "}
          <span className="bold">initiating party</span> and the case is still
          open!
        </p>,
        "Oops!",
        { width: "33%" },
        true
      );
    }
  };

  const handleActionMenuDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuDropdownClose = () => {
    setAnchorEl(null);
  };

  /*
   * If Loading or in error, return those components
   */
  if (!currentCase) {
    return <div>loading</div>;
  }

  if (error) {
    return (
      <Error
        stateProp={{
          statusCode: "404",
          reason: "Sorry, we cannot find that case",
        }}
      />
    );
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-2" : undefined;

  return (
    <div className="singular-case-page-container">
      <Link to="/caselist" className="btn">
        Back to Cases List
      </Link>
      <div className="section singular-case-top">
        <h1 className="case-id-header">
          {" "}
          Case Id: {currentCase.caseId.substring(0, 6)}
        </h1>
        <Fab
          sx={{
            zIndex: 50,
          }}
          onClick={(event) => handleActionMenuDropdownClick(event)}
        >
          <SlOptionsVertical
            className={"options-icon " + (open && " active")}
          />
        </Fab>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleActionMenuDropdownClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              minWidth: "200px",
              borderRadius: "2rem",
            }}
          >
            <div
              className={
                "singular-case-options-dropdown-option " +
                (isCaseCloseable ? " enabled" : " disabled")
              }
              onClick={closeCase}
            >
              Close Case
            </div>
          </Box>
        </Popover>
      </div>
      {caseOpened(currentCase) || (
        <p className="section informational">
          <FaInfoCircle /> This case is currently closed. You may still add
          comments, but there is no further action to take at this time
        </p>
      )}
      <div className="line" />
      <CaseHeader currentCase={currentCase} />
      <div className="line" />
      <div className="section requested-container">
        <h2>Requested TNs</h2>
        <RequestedTns currentCase={currentCase} />
      </div>
      <div className="line" />
      <div className="section loa-section-container">
        <h2>Supporting LOAs</h2>
        <Loas
          loas={currentCase.loas}
          caseId={caseId}
          nnidType={currentCase.nnidType}
        />
      </div>
      <div className="line" />
      <div className="section comments-section-container">
        <h2>Activity</h2>
        <Activity
          comments={currentCase.comments}
          caseId={caseId}
          openedBy={currentCase.openedBy}
        />
      </div>
    </div>
  );
};

export default SingularCase;
