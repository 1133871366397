import { useSelector } from "react-redux";
import { NNID_TYPE_MAPPING } from "../../utils/nnidTypeEnums";
import { downloadFileAxios } from "../../service/conflictCaseService";
import { Button } from "@mui/material";

/**
 * Component for Supporting LOAs section of a Singular Case
 *
 * @param {list} loas List of LOA objects to be displayed associated with the case.
 * @param {String} nnidType The Authorization Policy of the requesting NNID of the case
 *
 * @returns {Component}
 */
const Loas = ({ loas, caseId, nnidType }) => {
  /**
   * Downloads file a static endpoint.
   *
   * @param {String} fileId fileId to download
   * @param {String} displayName the name the file will be downloaded as.
   */
  const downloadFile = (fileName, displayName, view) => {
    downloadFileAxios(caseId, fileName).then((res) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", displayName);

      if (view) {
        link.setAttribute("target", "_blank");
      }

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  // const displayFile = (fileName, displayName) => {
  //   setShowAttachment(true);
  // };
  if (nnidType !== "SUBSCRIBER_LOA") {
    return (
      <div>
        There are no LOAs associated with a {NNID_TYPE_MAPPING[nnidType]} case
      </div>
    );
  }
  if (!loas || loas.length === 0) {
    return <div>There are no LOAs associated with this case...</div>;
  }

  return (
    <div className="loas-container">
      {loas.map((loa) => {
        return (
          <div className="loa" key={loa.fileName}>
            <div className="loa-title"> {loa.fileName}</div>
            <div className="loa-controls">
              <Button
                variant="outlined"
                onClick={() => downloadFile(loa.fileName, loa.fileName, false)}
              >
                Download
              </Button>
              {/* <button
                  onClick={() => downloadFile(loa.fileName, loa.fileName, true)}
                >
                  View
                </button> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { Loas };
