import userManager, { loadUserFromStorage } from "./userServices";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import logger from "../utils/logger";

const checkIntervalSeconds = 5;
const expirationLeadTimeSeconds = 90;

const SilentSignin = () => {
  let expires_at = useSelector((state) => state.auth.user.expires_at);

  const checkSilentSignin = async () => {
    const now = Math.floor(new Date().getTime() / 1000);
    if (
      expires_at === undefined ||
      expires_at < now + expirationLeadTimeSeconds
    ) {
      logger.log("==========user Expiring: SilentSignin ");
      await userManager
        .signinSilent()
        .then(async (res) => {
          logger.log(res);
          await userManager.storeUser(res);
          expires_at = res.expires_at;
          //loadUserFromStorage(store);
        })
        .catch((err) => {
          userManager.signinRedirect();
        });
    }
  };

  useEffect(() => {
    const checkSilentSigninInterval = setInterval(
      checkSilentSignin,
      checkIntervalSeconds * 1000
    );

    return () => {
      clearInterval(checkSilentSigninInterval);
    };
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default SilentSignin;
