import { createSlice } from "@reduxjs/toolkit";
import {
  markAllAsRead,
  markAsReadUnread,
  setIsLoading,
  setNotificationList,
} from "../reducer/notificationsReducers";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    isLoading: false,
    notificationList: [],
    error: {
      isError: false,
      errorMessage: "",
      errorTs: null,
      errorDisplayTime: 10000,
    },
  },
  reducers: {
    markAsReadUnread,
    markAllAsRead,
    setIsLoading,
    setNotificationList,
  },
});

const notificationActions = notificationSlice.actions;

export { notificationActions, notificationSlice };
