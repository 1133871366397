import axios from "./axios";

import {
  BASE_URL,
  GET_NOTIFICATIONS_ENDPOINT,
  POST_READ_ALL_NOTIFICATIONS_ENDPOINT,
  POST_READ_UNREAD_NOTIFICATION_ENDPOINT,
} from "./serviceConfiguration";

function fetchNotificationsAxios(pid) {
  return axios.get(BASE_URL + GET_NOTIFICATIONS_ENDPOINT);
}

function postReadUnreadNotificationAxios(createdTs, readUnread) {
  return axios.post(BASE_URL + POST_READ_UNREAD_NOTIFICATION_ENDPOINT, {
    createdTs: createdTs,
    readUnread: readUnread,
  });
}

function postReadAllNotificationsAxios() {
  return axios.post(BASE_URL + POST_READ_ALL_NOTIFICATIONS_ENDPOINT);
}

export {
  fetchNotificationsAxios,
  postReadUnreadNotificationAxios,
  postReadAllNotificationsAxios,
};
