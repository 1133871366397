import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import { FaRegWindowClose } from "react-icons/fa";
import "./AlertModal.scss";
import { errorActions } from "../../store/errorSlice";

/**
 *
 * @param {error} Object Object containing the error details. Needs the following fields: isError, errorTs (for error codes), errorDisplayTime, and errorMessage
 * @param {closeModal} Method Method to hide the modal
 * @param {clearError} Method Method to change the error state back to it's initial state
 * @returns
 */
const AlertModal = ({ error, closeModal, clearError }) => {
  const [currentAlertModalProp, setCurrentAlertModalProp] = useState(<></>);

  const { isError, errorMessage, errorTs, errorDisplayTime } = error;

  useEffect(() => {
    let alertModalPromise1, alertModalPromise2;

    setCurrentAlertModalProp(
      <div>
        <p>{errorMessage}</p>
        {errorTs !== 0 && <p className="italic">Errorcode: {errorTs}</p>}
      </div>
    );

    alertModalPromise1 = setTimeout(() => {
      closeModal();
    }, errorDisplayTime);

    alertModalPromise2 = setTimeout(() => {
      clearError();
    }, errorDisplayTime + 1000);

    return () => {
      clearTimeout(alertModalPromise1);
      clearTimeout(alertModalPromise2);
    };
  }, [isError]);

  return (
    <div
      className={"alert-modal-container " + (isError ? "visible" : "hidden")}
    >
      <div className="alert-modal-close-button-container" onClick={closeModal}>
        <FaRegWindowClose fontSize="1.5rem" />
      </div>
      {currentAlertModalProp}
    </div>
  );
};

export default AlertModal;
