import { useSidebarContext } from "../../context/SidebarContext";
import { useNavigate } from "react-router-dom";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { useSelector, useDispatch } from "react-redux";
import { adminActions } from "../../store/adminSlice";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import { notificationActions } from "../../store/notificationsSlice";
import { signoutRedirect } from "../../auth/userServices";
import { initialSyncAsProvisionerAdminMiddleware } from "../../middleware/adminMiddleware";
import DropDown from "../../components/forms/DropDown";
import { BiLogOut } from "react-icons/bi";
import { Box } from "@mui/system";

/**
 * Component of the sidebar which comes in and out when sidebar is enabled.
 *
 * @returns Component
 */
const SideBar = () => {
  const { showSidebar, setShowSidebar } = useSidebarContext();
  const navigate = useNavigate();
  const isAdminAuthenticated = useIsAdminAuthenticated();
  const provisionerList = useSelector((state) => state.admin.provisionerList);
  const currentPid = useSelector((state) => state.admin.currentPid);
  const dispatch = useDispatch();

  const selectProvisioner = (provisioner) => {
    dispatch(adminActions.setCurrentPid(provisioner));
    dispatch(initialSyncAsProvisionerAdminMiddleware());
    setShowSidebar(false);
  };

  const redirectToPage = (page) => {
    navigate(page);
    setShowSidebar(false);
  };

  return (
    <>
      <div className={"sidebar " + (!showSidebar ? "hidden" : "visible")}>
        <div className="sidebar-section">
          {isAdminAuthenticated() && (
            <div className="sidebar-option provisioner-select">
              <Box sx={{ backgroundColor: "white", p: 1, borderRadius: 2 }}>
                <h2>Select a Provisioner</h2>
                <DropDown
                  placeholder="Please select an Provisioner..."
                  list={provisionerList === null ? [] : provisionerList}
                  onClick={(provisioner) => {
                    if (provisioner) {
                      selectProvisioner(provisioner);
                    }
                  }}
                  displayValueInit={currentPid ? currentPid.display : ""}
                  id=""
                />
              </Box>
            </div>
          )}
          <button
            className="sidebar-option"
            onClick={() => {
              if (isAdminAuthenticated()) {
                redirectToPage("admin/caselist");
              } else {
                redirectToPage("caselist");
              }
            }}
          >
            Cases
          </button>
          {!isAdminAuthenticated() && (
            <>
              <button
                className="sidebar-option"
                onClick={() => redirectToPage("/newcase")}
              >
                New
              </button>
            </>
          )}
          <button
            className="sidebar-option"
            onClick={() => {
              dispatch(notificationActions.setNotificationList([]));
              dispatch(conflictCaseActions.setCaseList([]));
              signoutRedirect();
            }}
          >
            <BiLogOut /> Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default SideBar;
