/**
 * Base url
 * TODO: Implement as ENV Variable
 */

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Notification Endpoints
 */

export const GET_NOTIFICATIONS_ENDPOINT = "get-notifications";
export const POST_READ_UNREAD_NOTIFICATION_ENDPOINT =
  "read-unread-notification";
export const POST_READ_ALL_NOTIFICATIONS_ENDPOINT = "read-all-notifications";

/**
 * Case Endpoints
 */

export const GET_CASES_ENDPOINT = "get-cases";
export const GET_CASE_ENDPOINT = "get-case";
export const POST_CREATE_CASE_ENDPOINT = "create-case";
export const POST_SUBMIT_COMMENT_ENDPOINT = "submit-comment";
export const POST_ACCEPT_TNS_ENDPOINT = "respond-tns-accept";
export const POST_REJECT_TNS_ENDPOINT = "respond-tns-reject";
export const ATTEMPT_PROVISION_TNS_ENDPOINT = "attempt-provision";
export const GET_NNID_LIST_ENDPOINT = "get-nnid-list";
export const INITIAL_SYNC_ENDPOINT = "initial-sync";
export const CLOSE_UNFINISHED_CASE_ENDPOINT = "close-unfinished-case";

/**
 * Auth Endpoints (Potentially will be deprecated later)
 */

export const LOGIN_ENDPOINT = "initial-sync";
export const CREATE_PROVISIONER_ENDPOINT = "create-provisioner";
export const CREATE_USER_ENDPOINT = "create-user";

const ADMIN_URL_PREFIX = "admin/";

export const GET_CASES_ENDPOINT_ADMIN = ADMIN_URL_PREFIX + "get-cases";
export const GET_CASE_ENDPOINT_ADMIN = ADMIN_URL_PREFIX + "get-case";
export const GET_NOTIFICATIONS_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "get-notifications";
export const INITIAL_SYNC_ENDPOINT_ADMIN = ADMIN_URL_PREFIX + "initial-sync";
export const INITIAL_SYNC_AS_PROVISIONER_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "login-as";
