import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import Pagination from "../../components/ui/Pagination";
import { NOTIFICATION_TYPE_MAPPING } from "../../utils/notificationEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationsMiddleware,
  markAllNotificationsAsReadMiddleware,
  markNotificationAsReadUnreadMiddleware,
} from "../../middleware/notificationMiddleware";
import { partitionNotificationsByDate } from "../../utils/notificationUtils";
import { Button } from "@mui/material";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";

const Notifications = () => {
  const notifications = useSelector(
    (state) => state.notification.notificationList
  );
  const dispatch = useDispatch();
  const [viewUnread, setViewUnread] = useState(false);
  const useIsAdmin = useIsAdminAuthenticated();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    dispatch(fetchNotificationsMiddleware());
  }, []);

  /**
   * Marks all notifications as read, updates global notifications state.
   */
  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsReadMiddleware());
  };

  /**
   * Updates viewUnread state to the opposite of it's current value.
   */
  const viewOnlyUnread = () => {
    setViewUnread(!viewUnread);
  };

  return (
    <div className="notifications-page">
      <h1 className="notification-page-header">Notifications</h1>
      <div className="notifications-container">
        <div className="notifications-page-controls">
          {!isAdmin && (
            <>
              <Button variant="outlined" onClick={viewOnlyUnread}>
                {viewUnread ? "View All" : "View Unread"}
              </Button>
              <Button variant="outlined" onClick={markAllAsRead}>
                Mark all as read
              </Button>
            </>
          )}
        </div>
        <div className="notification-list">
          <Pagination
            itemsPerPage={10}
            items={
              viewUnread
                ? notifications.filter((notification) => !notification.read)
                : notifications
            }
            Display={NotificationPaginatedDisplay}
            other={{ viewUnread }}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Returns component responsible for organizing notifications based on the date they were created.
 *
 * @param {List} currentItems List or Notification Objects
 * @param {boolean} viewUnread viewUnread state. If true, will filter displayed values.
 *
 * @returns Component
 */
const NotificationPaginatedDisplay = ({ currentItems, viewUnread }) => {
  const [organizedNotifications, setOrganizedNotifications] = useState({});
  const [dateList, setDateList] = useState([]);

  useEffect(() => {
    let organizedNotifications = partitionNotificationsByDate(currentItems);
    setOrganizedNotifications(organizedNotifications);
    let dateList = Object.keys(organizedNotifications);
    dateList.sort((a, b) => new Date(b) - new Date(a));
    setDateList(dateList);
  }, [currentItems, viewUnread]);

  return (
    <>
      {dateList.length > 0 ? (
        dateList.map((date) => {
          return (
            <div className="notification-date-container" key={date}>
              <h2 className="notification-date-header">{date}</h2>
              <div className="line"></div>
              {organizedNotifications[date].map((notification, index) => {
                return (
                  <Notification
                    key={notification.notificationId}
                    {...notification}
                  />
                );
              })}
            </div>
          );
        })
      ) : (
        <div className="no-notifications-container">
          You have no {viewUnread ? "unread" : ""} notifications.
        </div>
      )}
    </>
  );
};

/**
 * Component associated with a single notification.
 *
 * @param {String} caseId caseId associated with this Notificaiton
 * @param {String} notificationId notificationId of this notification
 * @param {String} header header to be displayed in this notification component
 * @param {String} text text to be displayed in this notification component
 * @param {boolean} read Boolean value if this notification has been read or not
 * @returns Component
 */
const Notification = ({
  caseId,
  notificationId,
  header,
  text,
  read,
  timestamp,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useIsAdmin = useIsAdminAuthenticated();
  const isAdmin = useIsAdmin();

  const markAsReadUnread = (e) => {
    e.stopPropagation();
    dispatch(markNotificationAsReadUnreadMiddleware(timestamp, !read));
  };

  return (
    <div
      key={notificationId}
      className={"notification selectable-shadow" + (read ? " read" : "")}
      onClick={() => {
        navigate(`/case/${caseId}`);
      }}
    >
      <div className="notification-header">
        {NOTIFICATION_TYPE_MAPPING[header]}
      </div>
      <div className="notification-text">{text}</div>
      <div className="notification-footer">
        {!isAdmin && (
          <Button onClick={markAsReadUnread}>
            Mark as {!read ? "Read" : "Unread"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Notifications;
