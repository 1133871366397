import { useEffect } from "react";
import { signinSilentCallback } from "../../auth/userServices";
import logger from "../../utils/logger";

export const SilentRefresh = () => {
  useEffect(() => {
    async function signinAsync() {
      logger.log("Inside SilentRefreshPage!");
      await signinSilentCallback()
        .then((res) => {
          logger.log("Test SilentRefreshPage success!!", res);
        })
        .catch((err) => {
          console.error("Test SilentRefreshPage Error!!", err);
        });
    }
    signinAsync();
  }, []);

  return <div>Redirecting...</div>;
};
