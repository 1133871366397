/**
 *
 * @param {Integer} d The ms since epoch Date you'd like to convert
 * @returns {String} the String value of the d
 */

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function getTimeString(ts) {
  let d = new Date(ts);
  let dformat =
    weekdays[d.getDay()] +
    " " +
    [d.getMonth() + 1, d.getDate(), ("" + d.getFullYear()).substring(2)].join(
      "/"
    ) +
    " " +
    [
      d.getHours() % 12 === 0 ? 12 : d.getHours() % 12,
      d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
    ].join(":") +
    " " +
    (d.getHours() > 11 ? "PM" : "AM");
  return dformat;
}

export function getTimeStringShort(ts) {
  let d = new Date(ts);
  let dformat =
    weekdays[d.getDay()] +
    " " +
    [d.getMonth() + 1, d.getDate()].join("/") +
    " " +
    [
      d.getHours() % 12 === 0 ? 12 : d.getHours() % 12,
      d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
    ].join(":") +
    " " +
    (d.getHours() > 11 ? "PM" : "AM");
  return dformat;
}
