import useIsUserAuthenticated from "../../hooks/useIsUserAuthenticated";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  markNotificationAsReadUnreadMiddleware,
  markAllNotificationsAsReadMiddleware,
} from "../../middleware/notificationMiddleware";

import { NOTIFICATION_TYPE_MAPPING } from "../../utils/notificationEnum";
import { Button } from "@mui/material";

const NotificationDropdown = ({ handleFilterDropdownClose }) => {
  const isAdminAuthenticated = useIsAdminAuthenticated();
  const isUserAuthenticated = useIsUserAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unreadNotifications = useSelector((state) =>
    state.notification.notificationList.filter((notification) => {
      return !notification.read;
    })
  );

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsReadMiddleware());
  };

  return (
    <div className="dropdown notifications-dropdown">
      <div className="dropdown-controls">
        {!isAdminAuthenticated() && (
          <Button
            variant="outlined"
            className="readall-button"
            onClick={markAllAsRead}
          >
            Read all
          </Button>
        )}

        <Button
          variant="outlined"
          className="notifications-page-button"
          onClick={() => {
            if (isAdminAuthenticated()) {
              navigate(`/admin/notifications`);
            } else if (isUserAuthenticated()) {
              navigate(`/notifications`);
            } else {
              navigate("");
            }
            handleFilterDropdownClose();
          }}
        >
          Go to Notifications
        </Button>
      </div>
      <ul className="dropdown-list">
        {unreadNotifications.length > 0 ? (
          unreadNotifications.map((notification) => {
            if (notification.read !== true) {
              return (
                <Notification
                  key={notification.notificationId}
                  {...notification}
                  setExpanded={handleFilterDropdownClose}
                />
              );
            }
          })
        ) : (
          <div style={{ padding: "1rem" }}>
            You have no unread notifications
          </div>
        )}
      </ul>
    </div>
  );
};

/**
 * Component of a single notification object on the notification dropdown
 *
 * @param {String} notificationId the notificationId of the notification Object
 * @param {String} header the header of the notification Object
 * @param {String} text the notification text of the notification Object
 * @param {String} caseId the caseId associated with this comment of the notification Object
 * @param {Function} setExpanded function to set the state of the NotificationDropdown is expanded or not
 * @param {Function} markNotificationAsRead function to mark a single notification as red based on notificationId
 * @returns {Component}
 */
const Notification = ({
  notificationId,
  header,
  text,
  caseId,
  setExpanded,
  timestamp,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useIsUserAuthenticated();
  const isAdminAuthenticated = useIsAdminAuthenticated();

  function handleClick() {
    setExpanded();
    if (isAdminAuthenticated()) {
      navigate(`/admin/case/${caseId}`);
    } else if (isAuthenticated()) {
      navigate(`/case/${caseId}`);
    }
  }

  const markNotificationAsRead = (timestamp) => {
    dispatch(markNotificationAsReadUnreadMiddleware(timestamp, true));
  };

  return (
    <>
      <div
        className="notification-modal"
        key={notificationId}
        onClick={handleClick}
      >
        <h3 className="notification-header">
          {NOTIFICATION_TYPE_MAPPING[header]}
        </h3>
        <p className="notification-text">{text}</p>
        {!isAdminAuthenticated() && (
          <Button
            className="notification-read-button"
            onClick={(e) => {
              e.stopPropagation();
              markNotificationAsRead(timestamp);
            }}
          >
            Mark as Read
          </Button>
        )}
      </div>
    </>
  );
};

export default NotificationDropdown;
