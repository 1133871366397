import { useState, useEffect } from "react";
import DropDown from "./DropDown";
import { getDaysInMonth, getMonths } from "../../utils/dateUtils";

const MONTHS = getMonths();
const YEARS = [
  {
    display: "2023",
    value: 2023,
  },
];

/**
 * Component with 3 dropdowns for Year, Month, and Day select.
 *
 * @param {Method} onChange method that will be called after year, month, and days input have all been selected
 * @returns {Component}
 */
const DateInput = ({ onChange }) => {
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [selectableDays, setSelectableDays] = useState([]);
  const [day, setDay] = useState(null);

  useEffect(() => {
    if (year && month) {
      setSelectableDays(getDaysInMonth(year.value, month.value));
    }
  }, [year, month]);

  useEffect(() => {
    if (month && day && year) {
      let currentDate = new Date(year.value, month.value, day.value);
      onChange({
        value: currentDate.getTime(),
        display: `${month.display} ${day.display} ${year.display}`,
      });
    }
  }, [month, day, year]);

  return (
    <div className="date-input-container">
      <div>Year</div>
      <div>Month</div>
      <div>Day</div>
      <DropDown
        list={YEARS}
        placeholder="Year"
        onClick={(year) => setYear(year)}
      />
      <DropDown
        placeholder="Month"
        list={MONTHS}
        onClick={(month) => setMonth(month)}
      />
      <DropDown
        placeholder="Date"
        disabled={!month || !year}
        list={selectableDays}
        onClick={(day) => setDay(day)}
      />
    </div>
  );
};

export default DateInput;
