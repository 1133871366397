/**
 * The panel component for Provision panel
 *
 * @param {*} text String, the text that is displayed on the left column of the panel
 * @param tns List of Strings, the tns that will be displayed in this section
 * @returns Component
 */
import { useSelector } from "react-redux";

const PROVISIONED_TEXT =
  "Number(s) had no conflict with the nnSR and are now successfuly provisioned to your NNID!";

const ProvisionedPanel = () => {
  const tns = useSelector(
    (state) => state.conflictCase.newCaseResultsData.provisioned
  );

  return (
    <div className="result-group">
      <div className="result-group-text">{PROVISIONED_TEXT}</div>
      <div className="result-group-results unable-provisioned">
        {tns.map((tn) => {
          return (
            <li className="tn" key={tn}>
              {tn}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export { ProvisionedPanel };
