import Accordian from "../../components/ui/Accordian";
import React, { useState, useRef } from "react";
import { useModalContext } from "../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import { ProvisionedPanel } from "./newCaseResultsPanels/ProvisionedPanel";
import { UnableProvisionPanel } from "./newCaseResultsPanels/UnableProvisionPanel";
import { ErrorProvisioningPanel } from "./newCaseResultsPanels/ErrorProvisioningPanel";
import { Button } from "@mui/material";

/**
 * The Component associated with step 2 of the open new case process. Will take a list of TNs w/ results
 * and parse them into associated ui blocks, and display them in an accordian view.
 *
 * @returns Component
 */
const NewCaseResults = () => {
  const { displayModal, closeModal } = useModalContext();
  const currentData = useSelector(
    (state) => state.conflictCase.newCaseResultsData
  );

  const dispatch = useDispatch();

  /**
   * Either:
   *  1) Returns user to Step 1 of New Case flow
   *  2) If they still have any cases to open, display a modal warning them they may
   *     lose that data.
   */
  const onClickBackButton = () => {
    let hasOpenCase = Object.keys(currentData.error).reduce(
      (accumulator, value) => {
        return accumulator || !currentData.error[value].opened;
      },
      false
    );

    if (hasOpenCase) {
      displayModal(
        <BackButtonModalContent closeModal={closeModal} />,
        "Warning!",
        { width: "50%" },
        true
      );
    } else {
      dispatch(conflictCaseActions.setNewCaseCurrentPage(0));
    }
  };

  return (
    <div className="new-case-upload">
      <Accordian
        panels={[
          {
            header:
              "Provisioned Successfully: " + currentData.provisioned.length,
            component: <ProvisionedPanel />,
            expanded: currentData.provisioned.length > 0,
          },
          {
            header:
              "Error Provisioning: " +
              Object.keys(currentData.error).reduce(
                (value, prov) => currentData.error[prov].tns.length + value,
                0
              ),
            component: <ErrorProvisioningPanel />,
            expanded:
              Object.keys(currentData.error).reduce(
                (value, prov) => currentData.error[prov].tns.length + value,
                0
              ) > 0,
          },
          {
            header:
              "Unable to Provision: " +
              Object.keys(currentData.unable).reduce(
                (value, reason) => currentData.unable[reason].length + value,
                0
              ),
            component: <UnableProvisionPanel />,
            expanded:
              Object.keys(currentData.unable).reduce(
                (value, reason) => currentData.unable[reason].length + value,
                0
              ) > 0,
          },
        ]}
      />
      <Button variant="outlined" onClick={onClickBackButton}>
        Back to step 1
      </Button>
    </div>
  );
};

/**
 * This component is the component that will be represented in the modal that is opened when the
 * user tries to hit the "Back to step 1" button.
 *
 * @param closeModal Function. Closes the Modal
 * @returns
 */
const BackButtonModalContent = ({ closeModal }) => {
  const dispatch = useDispatch();

  return (
    <>
      <p>Note: You still have cases to potentially open!</p>
      <p>
        Any provisioning requests that have yet to have been created will not be
        saved!
      </p>
      <p>Please finish creating any cases before trying to submit more!</p>
      <div className="back-modal-control-container">
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            dispatch(conflictCaseActions.setNewCaseCurrentPage(0));
            closeModal();
          }}
        >
          Continue to step 1
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Remain on this page
        </Button>
      </div>
    </>
  );
};

export default NewCaseResults;
