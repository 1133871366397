import { setAuthHeader } from "../service/axios";
import { authResultIsAdmin, authResultGetPid } from "../utils/authUtils";

export function setEmailAndPid(state, action) {
  state.email = action.payload.email;
  state.pid = action.payload.pid;
  localStorage.setItem("email", state.email);
  localStorage.setItem("pid", state.pid);
}

export function setPid(state, action) {
  state.pid = action.payload;
}

export function setEmail(state, action) {
  state.email = action.payload;
}

export function logout(state, action) {
  state.email = null;
  state.pid = null;
  localStorage.removeItem("email");
  localStorage.removeItem("pid");
}

export function setMustCreateUser(state, action) {
  state.mustCreateUser = action.payload;
}

export function setMustCreateProvisioner(state, action) {
  state.mustCreateProvisioner = action.payload;
}

export function setProvisionerCommonName(state, action) {
  state.provisionerCommonName = action.payload;
}

export function storeUser(state, action) {
  let user = action.payload;
  setAuthHeader(user.id_token);
  state.user = user;
  state.displayName = user.profile.nickname;
  state.email = user.profile.email;
  state.pid = authResultGetPid(user);
  state.isAdmin = authResultIsAdmin(user);
  state.userError = false;
  state.isLoading = false;
}

export function storeUserError(state, action) {
  state.user = {};
  state.isLoading = false;
  state.userError = true;
}

export function setIsLoading(state, action) {
  state.isLoading = action.payload;
}
