/**
 * The different fields that the filter dropdown will display.
 *
 *  display -> What id displayed on the dropdown
 *  value -> the field of cases that correspondes to this field, also to the operators object
 *  typeDisplay -> The type of display for value section that is shown when that field is selected
 */
const FIELD_VALUES = [
  {
    display: "Case ID",
    value: "caseId",
    typeDisplay: "dropdown",
  },
  {
    display: "Status",
    value: "status",
    typeDisplay: "dropdown",
  },
  {
    display: "Opened By",
    value: "openedBy",
    typeDisplay: "dropdown",
  },
  {
    display: "Opened Against",
    value: "openedAgainst",
    typeDisplay: "dropdown",
  },
  {
    display: "# TNs",
    value: "numberTns",
    typeDisplay: "text-box",
  },
  {
    display: "Opened On",
    value: "openedTs",
    typeDisplay: "date",
  },
  {
    display: "Expiration Date",
    value: "expiresTs",
    typeDisplay: "date",
  },
];

/**
 * The different operators for each of the different fields.
 *
 *  key: the value of the data associated to that set of filters
 *  display: what will be displayed in the operatators dropdown
 *  value: the value of that operator. Corresponds to the filter function that will be called below.
 */
const OPERATOR_VALUES = {
  caseId: [
    {
      display: "Is",
      value: "is",
    },
  ],
  status: [
    {
      display: "Is",
      value: "is",
    },
    {
      display: "Is Not",
      value: "isNot",
    },
  ],
  openedBy: [
    {
      display: "Is",
      value: "is",
    },
    {
      display: "Is Not",
      value: "isNot",
    },
  ],
  openedAgainst: [
    {
      display: "Is",
      value: "is",
    },
    {
      display: "Is Not",
      value: "isNot",
    },
  ],
  numberTns: [
    {
      display: "Greater Than",
      value: "greaterThan",
    },
    {
      display: "Less Than",
      value: "lessThan",
    },
  ],
  openedTs: [
    {
      display: "After",
      value: "greaterThan",
    },
    {
      display: "Before",
      value: "lessThan",
    },
  ],
  expiresTs: [
    {
      display: "After",
      value: "greaterThan",
    },
    {
      display: "Before",
      value: "lessThan",
    },
  ],
};

/**
 * Different functions that the caseList filtering functionality can use. What filter function
 * is called is dependent on the "value" field of the operator selected
 *
 * is -> returns only rows that have the EXACT value of the filter object
 * isNot -> returns only rows that have ARENT exact value of the filter object
 * greaterThan -> returns only rows that have LESS of the value of the filter object
 * lessThan -> returns only rows that have MORE of the value of the filter object
 *
 */
const FILTER_FUNCTIONS = {
  is: (rows, filterObj) => {
    return rows.filter((row) => row[filterObj.field] === filterObj.value);
  },
  isNot: (rows, filterObj) => {
    return rows.filter((row) => row[filterObj.field] !== filterObj.value);
  },
  greaterThan: (rows, filterObj) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => row[filterObj.field] > value);
  },
  lessThan: (rows, filterObj) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => row[filterObj.field] < value);
  },
};

export { FIELD_VALUES, OPERATOR_VALUES, FILTER_FUNCTIONS };
