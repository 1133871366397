import { useTable, useSortBy, usePagination } from "react-table";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import FilterModal from "./FilterModal";
import { getHeaders } from "./Headers";
import { useSelector, useDispatch } from "react-redux";
import { fetchCasesMiddleware } from "../../middleware/conflictCaseMiddleware";
import { FILTER_FUNCTIONS } from "./filterStaticValues";
import { FilterListItems } from "./FilterListItems";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { getTimeStringShort } from "../../utils/timeUtils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { useTheme } from "@emotion/react";
import { FaFilter } from "react-icons/fa";
import { Paper, Popover, Toolbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const INITIAL_PAGE_SIZE = 10;

const CasesList = () => {
  const allCasesForUser = useSelector((state) => state.conflictCase.caseList);
  const pid = useSelector((state) => state.auth.pid);
  const filters = useSelector((state) => state.conflictCase.filters);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentDisplayedCases, setCurrentlyDisplayedCases] =
    useState(allCasesForUser);
  const [anchorEl, setAnchorEl] = useState(null);

  const applyFilters = () => {
    let tempCases = allCasesForUser;
    filters.forEach((filter) => {
      let filterFunction = FILTER_FUNCTIONS[filter.operator];
      tempCases = filterFunction(tempCases, filter);
    });

    setCurrentlyDisplayedCases(tempCases);
  };

  useEffect(() => {
    dispatch(fetchCasesMiddleware());
  }, []);

  useEffect(() => {
    setCurrentlyDisplayedCases(allCasesForUser);
  }, [allCasesForUser]);

  useEffect(() => {
    applyFilters();
  }, [filters, allCasesForUser]);

  const columns = React.useMemo(() => getHeaders(pid), []);
  const data = React.useMemo(() => {
    return currentDisplayedCases;
  }, [currentDisplayedCases]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["caseId"],
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useSortBy,
    usePagination
  );

  const handleFilterDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="cases-page-container">
        <Paper elevation={4}>
          <TableContainer>
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3">Your Cases</Typography>
              <Stack flexDirection="row">
                {filters.length > 0 && <FilterListItems />}
                <IconButton>
                  <FaFilter id={id} onClick={handleFilterDropdownClick} />
                </IconButton>
                <Popover
                  sx={{
                    padding: 4,
                  }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleFilterDropdownClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box
                    sx={{
                      width: "50vw",
                      overflowY: "visible",
                    }}
                  >
                    <FilterModal
                      closeFilterDropdown={handleFilterDropdownClose}
                    />
                  </Box>
                </Popover>
              </Stack>
            </Toolbar>
            <Table
              sx={{ minWidth: 650 }}
              size="large"
              aria-label="simple table"
            >
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        align="left"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Typography variant="body1" color="white">
                          {column.render("Header")}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                            : ""}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className="data-row selectable"
                      key={row.id}
                      onClick={() => {
                        navigate(`/case/${row.values.caseId}`);
                      }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            key={`${cell.column.id}/${cell.row.id}`}
                            sx={{
                              p: 4,
                              pl: 2,
                            }}
                            align="left"
                          >
                            <Typography>
                              {cell.column.Header !== "Opened On" && //Needed so that the sorting works as expected. If not, it'll do it alphabetically w/ the Date!
                              cell.column.Header !== "Expiration Date"
                                ? cell.render("Cell")
                                : getTimeStringShort(cell.value)}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={currentDisplayedCases.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={(event, newPage) => gotoPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setPageSize(parseInt(event.target.value, 10));
                      gotoPage(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default CasesList;
