import axios from "./axios";
import {
  BASE_URL,
  GET_CASES_ENDPOINT_ADMIN,
  GET_CASE_ENDPOINT_ADMIN,
  INITIAL_SYNC_ENDPOINT_ADMIN,
  GET_NOTIFICATIONS_ENDPOINT_ADMIN,
  INITIAL_SYNC_AS_PROVISIONER_ENDPOINT_ADMIN,
} from "./serviceConfiguration";

export function fetchCasesAxiosAdmin(pid) {
  return axios.get(BASE_URL + GET_CASES_ENDPOINT_ADMIN + `/${pid}`);
}

export function fetchCaseAxiosAdmin(pid, caseId) {
  return axios.get(
    BASE_URL + GET_CASE_ENDPOINT_ADMIN + `/${pid}` + `/${caseId}`
  );
}

export function initialSyncAxiosAdmin() {
  return axios.get(BASE_URL + INITIAL_SYNC_ENDPOINT_ADMIN);
}

export function initialSyncAsProvisionerAdmin(pid) {
  return axios.get(
    BASE_URL + INITIAL_SYNC_AS_PROVISIONER_ENDPOINT_ADMIN + `/${pid}`
  );
}

export function getNotificationsAdmin(pid) {
  return axios.get(BASE_URL + GET_NOTIFICATIONS_ENDPOINT_ADMIN + `/${pid}`);
}
