import React, { useEffect } from "react";
import { signoutRedirectCallback } from "../../auth/userServices";
import { useNavigate } from "react-router-dom";
import logger from "../../utils/logger";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    async function signoutAsync() {
      logger.log("You've landed on the LogoutCallback page!");
      await signoutRedirectCallback();
      navigate("/");
    }
    signoutAsync();
  }, [navigate]);

  return <div>Redirecting...</div>;
};

export default Logout;
