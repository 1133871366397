import { TextField } from "@mui/material";

/**
 * Simple text input component. Calls onChange when the textbox is entered.
 *
 * @param {Method} onChange
 * @returns {Component}
 */
const TextboxInput = ({
  onChange = () => {},
  inputRef,
  variant = "standard",
  label = "",
  style = {},
  size = "",
  type,
}) => {
  return (
    <TextField
      type={type}
      xs={style}
      size={size}
      label={label}
      variant={variant}
      onChange={onChange}
      inputRef={inputRef}
    ></TextField>
  );
};

export default TextboxInput;
