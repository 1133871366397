import { TextField } from "@mui/material";

/**
 * Simple text input component. Calls onChange when the textbox is entered.
 *
 * @param {Method} onChange
 * @returns {Component}
 */
const TextareaInput = ({
  onChange = () => {},
  inputRef,
  variant,
  label = "",
  sx = {},
  size = "",
  disabled = false,
  error = false,
}) => {
  return (
    <TextField
      multiline
      error={error}
      disabled={disabled}
      sx={sx}
      size={size}
      label={label}
      variant={variant}
      onChange={onChange}
      inputRef={inputRef}
    ></TextField>
  );
};

export default TextareaInput;
