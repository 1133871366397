import { createSlice } from "@reduxjs/toolkit";
import {
  setEmailAndPid,
  setPid,
  setEmail,
  logout,
  setMustCreateProvisioner,
  setMustCreateUser,
  setProvisionerCommonName,
  storeUser,
  storeUserError,
  setIsLoading,
} from "../reducer/authReducers";
import { setAuthHeader } from "../service/axios";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    pid: null,
    email: null,
    displayName: null,
    provisionerCommonName: null,
    userError: false,
    isLoading: false,
    isAdmin: false,
    user: {}, //all OIDC information
  },
  reducers: {
    setEmailAndPid,
    logout,
    setMustCreateProvisioner,
    setMustCreateUser,
    setPid,
    setEmail,
    setProvisionerCommonName,
    storeUser,
    storeUserError,
    setIsLoading,
  },
});

const authActions = authSlice.actions;

export { authSlice, authActions };
