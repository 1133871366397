import { UserManager } from "oidc-client";
import { authActions } from "../store/authSlice";
import { OIDC_CONFIG } from "./oidcConfig";
import logger from "../utils/logger";

/**
 * todo:
 * For some obnoxious reason, the env variables aren't working here...
 */

const userManager = new UserManager(OIDC_CONFIG);

export async function loadUserFromStorage(store) {
  return new Promise(async (resolve, reject) => {
    try {
      logger.log("loadUserFromStorage called");
      let user = await userManager.getUser();
      if (!user) {
        store.dispatch(authActions.storeUserError());
        reject({
          error: true,
          reason: "no user in storage",
        });
        return;
      }

      user = JSON.parse(JSON.stringify(user));
      store.dispatch(authActions.storeUser(user));
      resolve(user);
    } catch (e) {
      console.error(`User not found: ${e}`);
      store.dispatch(authActions.storeUserError());
    }
  });
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export function signinSilent() {
  return userManager.signinSilent();
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback();
}

export default userManager;
