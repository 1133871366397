const caseOpened = (singularCase) => {
  let open = false;

  if (singularCase.status === "CLOSED") {
    return false;
  }

  for (let i = 0; i < singularCase.tns.length && !open; i++) {
    open = !singularCase.tns[i].responded || open;
  }

  return open;
};

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
};

const isValidTn = (stringTn) => {
  const regex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  if (stringTn.length !== 11) return false;
  //if (!stringTn.match(regex)) return false;

  return true;
};

//used if the number is valid and you want to conver to E164
const normalizeTn = (stringTn) => {
  let chars = stringTn.split();
};

export { caseOpened, capitalize, isValidTn };
