import { createSlice, current } from "@reduxjs/toolkit";
import { setProvisionerList, setCurrentPid } from "../reducer/adminReducers";

const adminSlice = createSlice({
  name: "admin",
  initialState: initAdminState(),
  reducers: {
    setProvisionerList,
    setCurrentPid,
  },
});

function initAdminState() {
  let currentPid = localStorage.getItem("currentPid");

  if (currentPid !== null) {
    currentPid = JSON.parse(currentPid);
  }
  return {
    provisionerList: [],
    currentPid: currentPid,
  };
}
const adminActions = adminSlice.actions;

export { adminActions, adminSlice };
