import { useState, useRef } from "react";
import { useModalContext } from "../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { attemptProvisionNewCaseTnsMiddleware } from "../../middleware/conflictCaseMiddleware";
import DropDown from "../../components/forms/DropDown";
import { fileToText } from "../../utils/fileUtils";
import { isValidTn } from "../../utils/genericUtils";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup";
import { Button, Grid, Paper, Typography } from "@mui/material";
import TextboxInput from "../../components/forms/TextboxInput";
import TextareaInput from "../../components/forms/TextareaInput";

const FORMATTING_HELP_MODAL_CONTENT = (
  <>
    <p>
      <div className="bold">Number must be submitted as</div>
      <ol>
        <li>- 11 digits phone number (Areacode and 10 digits)</li>
        <li>- No spaces, dashes, dots, or pluses. Only digits</li>
        <li>- No duplicates</li>
      </ol>
    </p>
    <div className="bold">Some Examples</div>
    <ol>
      <li>
        <div className="tn">12012000008</div>
      </li>
      <li>
        <div className="tn">12012049579</div>
      </li>
      <li>
        <div className="tn">19899850111</div>
      </li>
    </ol>
    <p className="bold">For CSV Input</p>
    <p>
      If submitting a CSV, we will find any cell in the csv following the above
      description and interpret it as a number you are trying to submit. Cells
      are split by commas
    </p>
  </>
);

/**
 * The component associated with step 1 of Create new Case flow. Will allow user to input
 * NNID, TNs, and associated fields.
 *
 * @returns Component
 */
const NewCaseUpload = () => {
  const NO_ERROR_STATE = {
    nnidError: false,
    nnidErrorMessage: "",
    tnTextBoxError: false,
    tnTextBoxErrorMessage: "",
    csvFileError: false,
    csvFileErrorMessage: "",
  };

  const [uploadFormat, setUploadFormat] = useState("input-text");
  const [seperator, setSeperator] = useState("new-line");
  const customSeperatorRef = useRef(null);
  const inputTextAreaRef = useRef(null);
  const { displayModal } = useModalContext();
  const csvInputRef = useRef(null);
  const dispatch = useDispatch();
  const nnidList = useSelector((state) => state.conflictCase.nnidList);
  const nnid = useSelector((state) => state.conflictCase.newCaseNnid);
  const setNnid = (nnid) => dispatch(conflictCaseActions.setNewCaseNnid(nnid));

  const [inputError, setInputError] = useState(NO_ERROR_STATE);

  const changeUploadFormat = (e) => {
    setUploadFormat(e.target.value);
  };

  const changeSeperator = (e) => {
    setSeperator(e.target.value);
  };

  const showHelpModal = () => {
    displayModal(
      FORMATTING_HELP_MODAL_CONTENT,
      "Formatting Tips",
      { width: "50%" },
      true
    );
  };

  const submit = async () => {
    let tns;
    let error = false;

    if (nnid === null || nnid.value === "") {
      setInputError((inputError) => {
        return {
          ...inputError,
          nnidError: true,
          nnidErrorMessage: "You did not select an NNID!",
        };
      });
      error = true;
    }

    tns = parseInputText();

    if (tns === null || tns.length === 0) {
      setInputError((inputError) => {
        return {
          ...inputError,
          tnTextBoxError: true,
          tnTextBoxErrorMessage: "You did not enter any TNs!",
        };
      });
      error = true;
    }

    if (error) return;

    if (tns !== null && tns.length !== 0) {
      dispatch(attemptProvisionNewCaseTnsMiddleware(tns));
    }
  };

  const parseCSVFile = async () => {
    try {
      const file = Array.prototype.slice.call(csvInputRef.current.files)[0];
      const fileText = await fileToText(file);

      let tns = [];
      fileText.split("\n").forEach((line) => {
        line.split(",").forEach((value) => {
          value = value.trim();
          if (isValidTn(value)) {
            tns.push(value);
          }
        });
      });

      setInputError(NO_ERROR_STATE);

      //return tns;

      inputTextAreaRef.current.value = tns.join("\n");
    } catch (e) {
      setInputError({
        ...inputError,
        csvFileError: true,
        csvFileErrorMessage:
          "There was something wrong with your CSV file. Please check the file and upload again.",
      });

      return;
    }
  };

  const parseInputText = () => {
    let seperatorValue;
    switch (seperator) {
      case "new-line":
        seperatorValue = "\n";
        break;
      case "custom":
        seperatorValue = customSeperatorRef.current.value.trim();
        break;
    }

    if (uploadFormat === "upload-csv") {
      seperatorValue = "\n";
    }

    let tns = inputTextAreaRef.current.value.split(seperatorValue);
    tns = tns.map((tn) => tn.trim());

    if (tns[tns.length - 1] === "") {
      tns.pop();
    }

    let error = false;

    tns.forEach((tn) => {
      if (!isValidTn(tn)) {
        error = true;
      }
    });

    if (error) {
      setInputError({
        ...inputError,
        tnTextBoxError: true,
        tnTextBoxErrorMessage: "Atleast one TN is not formatted correctly",
      });
      return;
    }

    tns.sort();

    tns.forEach((tn, index, array) => {
      if (index !== array.length - 1 && tn === array[index + 1]) {
        error = true;
      }
    });

    if (error) {
      setInputError({
        ...inputError,
        tnTextBoxError: true,
        tnTextBoxErrorMessage:
          "There are atleast 2 tns that are duplicate of one another",
      });
      return;
    }

    setInputError((inputError) => {
      return {
        ...inputError,
        tnTextBoxError: false,
        tnTextBoxErrorMessage: "",
      };
    });
    return tns;
  };

  console.log(nnid);

  return (
    <Paper sx={{ p: 3 }} elevation={4}>
      <Grid container>
        <Grid className="new-case-upload-grid-item" item xs={4}>
          <span className="bold">NNID to Provision To: </span>
        </Grid>
        <Grid className="new-case-upload-grid-item" item xs={8}>
          {inputError.nnidError && (
            <div className="input-error">{inputError.nnidErrorMessage}</div>
          )}
          <DropDown
            id="nnid-selection"
            placeholder="nnid"
            list={nnidList}
            style={{}}
            error={inputError.nnidError}
            onClick={(nnid) => {
              setNnid(nnid);
              setInputError({
                ...inputError,
                nnidError: false,
                nnidErrorMessage: "",
              });
            }}
            initialValue={nnid}
          />
        </Grid>
        <Grid className="new-case-upload-grid-item" item xs={4}>
          <Typography variant="body" className="bold">
            Upload Option:
          </Typography>
        </Grid>
        <Grid className="new-case-upload-grid-item" item xs={8}>
          <RadioButtonGroup
            list={[
              { display: "Input Text", value: "input-text" },
              { display: "Upload CSV", value: "upload-csv" },
            ]}
            onClick={(value) => setUploadFormat(value)}
            initialValue={uploadFormat}
          ></RadioButtonGroup>
        </Grid>
        {uploadFormat === "input-text" ? (
          <>
            <Grid className="new-case-upload-grid-item" item xs={4}>
              <label>
                <span className="bold">Separator: </span>
              </label>
            </Grid>
            <Grid className="new-case-upload-grid-item" item xs={8}>
              <RadioButtonGroup
                list={[
                  { display: "New Line", value: "new-line" },
                  {
                    display: "Custom",
                    value: "custom",
                    children: <TextboxInput inputRef={customSeperatorRef} />,
                  },
                ]}
                onClick={(value) => setSeperator(value)}
              ></RadioButtonGroup>
            </Grid>

            <Grid className="new-case-upload-grid-item" item>
              <div id="text-area-title">
                <span className="bold">Input Numbers: </span>
              </div>
              {inputError.tnTextBoxError && (
                <div className="input-error">
                  {inputError.tnTextBoxErrorMessage}
                </div>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid className="new-case-upload-grid-item" item xs={4}>
              <label>
                <span className="bold">File Upload: </span>
              </label>
            </Grid>
            <Grid className="new-case-upload-grid-item" item xs={8}>
              <input
                id="csv-upload-cell"
                ref={csvInputRef}
                onChange={parseCSVFile}
                type="file"
              />
            </Grid>
            <Grid className="new-case-upload-grid-item" item xs={6}>
              <div id="text-area-title">
                <span className="bold">Uploaded Numbers: </span>
              </div>
            </Grid>
          </>
        )}
        {/* <TextField multiline ></TextField> */}
        <Grid className="new-case-upload-grid-item" item xs={12}>
          <TextareaInput
            sx={{ width: "100%", p: 0 }}
            error={inputError.tnTextBoxError}
            disabled={uploadFormat === "upload-csv"}
            multiline
            inputRef={inputTextAreaRef}
          />
        </Grid>{" "}
        <Grid className="new-case-upload-grid-item" item xs={6}>
          <Button variant="outlined" onClick={showHelpModal}>
            Help Formatting
          </Button>
        </Grid>
        <Grid
          className="new-case-upload-grid-item"
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <div className="bottom-controls"></div>
    </Paper>
  );
};

export default NewCaseUpload;
