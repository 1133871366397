import { useSelector } from "react-redux";

const UNABLE_TEXT =
  "Number(s) CANNOT be provisioned inside of the nnSR for one reason or another.";

const UNABLE_PROVISIONED_TEXT = {
  NP_LOA: "Your NNID has never been approved by the Number Provider",
  TOLL_FREE: "Your NNID has never been approved to provision Toll Free numbers",
  MOBILE:
    "Your NNID has never been approved to provision mobile numbers to the OSR",
  OTHER: "You are unable to provision those numbers in the OSR",
  PROVISIONER_NOT_PORTAL_ENABLED:
    "The following TNS belong to PIDs that are not currently portal enabled",
  PROTECTED_NNID:
    "This NP NNID cannot be handled on this portal. Please reach out to the NetNumber team to address this issue",
};

/**
 * The panel component for Unable to Provision panel
 *
 * @returns {Component}
 */
const UnableProvisionPanel = () => {
  const data = useSelector(
    (state) => state.conflictCase.newCaseResultsData.unable
  );

  return (
    <div className="result-group">
      <div className="result-group-text">{UNABLE_TEXT}</div>
      <div className="result-group-results">
        <div className="unable-group-header unable-prov-group">
          <div className="first-column">Reason</div>
          <div className="second-column">TNs</div>
        </div>
        {Object.keys(data).map((reason) => {
          return (
            <div className="unable-prov-group" key={reason}>
              <div className="unable-prov-reason first-column">
                {UNABLE_PROVISIONED_TEXT[reason]}
              </div>
              <div className="tns-list second-column">
                {data[reason].map((tn) => {
                  return (
                    <li className="tn" key={tn}>
                      {tn}
                    </li>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { UnableProvisionPanel };
