import { createSlice } from "@reduxjs/toolkit";
import { hideError, clearError, setError } from "../reducer/errorReducers";

const errorSlice = createSlice({
  name: "error",
  initialState: initialState(),
  reducers: {
    hideError,
    clearError,
    setError,
  },
});

function initialState() {
  return {
    error: {
      isError: false,
      errorMessage: "",
      errorTs: null,
      errorDisplayTime: 10000,
    },
  };
}

const errorActions = errorSlice.actions;

export { errorActions, errorSlice };
