import { useModalContext } from "../../context/ModalContext";
import { useDispatch } from "react-redux";
import { submitAcceptTnsMiddleware } from "../../middleware/conflictCaseMiddleware";
import { Button } from "@mui/material";

/**
 * Content of the modal displayed after user selects numbers and selects the accept button.
 *
 * @param {list} tns List of strings of TNs about to be accepted.
 * @param {String} provisioner String of provisioner name to be displayed on this modal
 * @param {function} closeModal function to close the modal
 * @param {function} respondTnsAcceptSubmit function to occur after the user has accepted the Tns
 * @returns {Component}
 */
const AcceptTnsModalContent = ({
  tns,
  provisioner,
  caseId,
  setSelectedTns,
}) => {
  const { closeModal } = useModalContext();
  const dispatch = useDispatch();

  /**
   *
   * @param {list} tns String list of TNs which the approve/reject modal was affecting
   * @param {boolean} acceptOrReject true means approve those numbers, false means reject
   * @param {string} additionalText any additional text you wish to be added to the system comment that states this action occured.
   */
  const respondTnsAcceptSubmit = (tns) => {
    dispatch(submitAcceptTnsMiddleware(provisioner, caseId, tns));

    setSelectedTns([]);
    closeModal();
  };

  if (tns.length === 0) {
    return (
      <p>
        You did not selected any TNs! Please select one or more and try again.
      </p>
    );
  }
  return (
    <>
      <p>You have selected to ACCEPT the following numbers:</p>
      <div className="tn-accept-reject-container">
        {tns.map((tn) => {
          return <li className="tn">{tn}</li>;
        })}
      </div>
      <p>
        Would you like to approve the transfer request from {provisioner}? Once
        you select accept,{" "}
        <span className="bold">
          you cannot undue the following action without re-provisioning{" "}
        </span>{" "}
        these numbers again yourself
      </p>
      <div className="accept-tns-control-container">
        <Button
          className="success"
          variant="contained"
          color="success"
          onClick={() => respondTnsAcceptSubmit(tns, true, null)}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          color="warning"
          className="warning"
          onClick={closeModal}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export { AcceptTnsModalContent };
