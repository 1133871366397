import { useSelector } from "react-redux";

const useIsAdminAuthenticated = () => {
  const { isAdmin } = useSelector((state) => state.auth);

  return () => {
    return isAdmin;
  };
};

export default useIsAdminAuthenticated;
