import { createContext, useState, useContext } from "react";
import ModalSkeleton from "../components/ui/ModalSkeleton";

const ModalContext = createContext();

const ModalContextProvider = ({ children }) => {
  //The fields that control the generic modal that appears up on the screen
  const [showModal, setShowModal] = useState(false);
  const [currentModalProp, setCurrentModalProp] = useState(null);

  //The fields that control the little dropdown alert modal
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentAlertModalProp, setCurrentAlertModalProp] = useState(<></>);

  const displayModal = (prop, header, style, allowExitFromWhitespace) => {
    const modal = (
      <ModalSkeleton
        closeModal={closeModal}
        header={header}
        style={style}
        allowExitFromWhitespace={allowExitFromWhitespace}
      >
        {prop}
      </ModalSkeleton>
    );
    setCurrentModalProp(modal);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentModalProp(<></>);
  };

  const displayAlertModal = (prop) => {
    setCurrentAlertModalProp(prop);
    setShowAlertModal(true);
  };

  const modalData = {
    showModal,
    displayModal,
    closeModal,
    showAlertModal,
    currentAlertModalProp,
    displayAlertModal,
    setShowAlertModal,
    setCurrentAlertModalProp,
  };

  return (
    <ModalContext.Provider value={modalData}>
      {children}
      {showModal && currentModalProp}
    </ModalContext.Provider>
  );
};

/**
 * Returns global state values, including:
 * - showModal (boolean) -> current value of if the modal is being shown currently
 * - displayModal (Function) -> Displays a modal of content you pass in
 * - closeModal (Function) -> Closes the modal
 * @returns {Object}
 */

const useModalContext = () => {
  let data = useContext(ModalContext);
  return data;
};

export { useModalContext, ModalContextProvider };
