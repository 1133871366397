function fileToByteArray(file) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            array = new Uint8Array(arrayBuffer);
          array.forEach((uint) => fileByteArray.push(uint));
          //   for (let byte in array) {
          //     fileByteArray.push(parseInt(byte));
          //   }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

function fileToText(file) {
  return new Promise((resolve, reject) => {
    try {
      let fileText = "";
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          fileText = evt.target.result;
          resolve(fileText);
        }
      };
    } catch (e) {
      reject(e);
    }
  });
}

export { fileToByteArray, fileToText };
