/**
 * Takes a list of notifications and will organize them based on the date they occured.
 *
 * @param {list} notifications List of notificaiton Objects, including timestamp
 * @returns {Object: Lists} Object of lists. Each object's key is the date, and value is a list of notification
 * objects that occured on that date.
 */
function partitionNotificationsByDate(notifications) {
  const tempSorting = {};
  const finalSorting = [];

  notifications.forEach((notification, index) => {
    let dateString = new Date(notification.timestamp).toDateString();
    if (!(dateString in tempSorting)) {
      tempSorting[dateString] = [];
    }
    tempSorting[dateString].push(index);
  });

  Object.keys(tempSorting).forEach((dateString) => {
    finalSorting[dateString] = [];

    tempSorting[dateString].forEach((ind) => {
      finalSorting[dateString].push(notifications[ind]);
    });
  });

  return finalSorting;
}

export { partitionNotificationsByDate };
