import { useSelector, useDispatch } from "react-redux";
import { FaRegWindowClose } from "react-icons/fa";
import { conflictCaseActions } from "../../store/conflictCasesSlice";

const FilterListItems = () => {
  const dispatch = useDispatch();
  const filtersList = useSelector((state) => state.conflictCase.filters);

  const removeFilterComponent = (id) => {
    dispatch(conflictCaseActions.removeFilter(id));
  };

  return (
    <>
      {filtersList.map(
        ({ fieldDisplay, operatorDisplay, valueDisplay, id }) => {
          return (
            <div className="filter btn" key={id}>
              <div className="filter-text">
                {fieldDisplay} {operatorDisplay} {valueDisplay}
              </div>
              <div className="close-filter-button">
                <FaRegWindowClose
                  fontSize="1.5rem"
                  className="close-fitler-button"
                  onClick={() => removeFilterComponent(id)}
                />
              </div>
            </div>
          );
        }
      )}
    </>
  );
};

export { FilterListItems };
