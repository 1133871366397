const envLevel = process.env.REACT_APP_LOGGING_LEVEL;
const NO_OP = () => {};

/**
 * Allowed Levels: [error, warn, log, debug]
 */
export class ConsoleLogger {
  constructor(options = { level: "warn" }) {
    const { level } = options;

    this.error = console.error.bind(console);

    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    if (level === "warn") {
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.log = console.log.bind(console);

    if (level === "log") {
      this.log = NO_OP;

      return;
    }

    this.debug = console.log.bind(console);
  }
}

export default new ConsoleLogger({ level: envLevel });
