import {
  initialSyncAxiosAdmin,
  initialSyncAsProvisionerAdmin,
  fetchCaseAxiosAdmin,
} from "../service/adminService";
import { adminActions } from "../store/adminSlice";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import { notificationActions } from "../store/notificationsSlice";
import { handleRequestError } from "./middlewareUtils";
import logger from "../utils/logger";

export const initialSyncAdminMiddleware = () => {
  return (dispatch, getState) => {
    if (getState().auth.isAdmin) {
      initialSyncAxiosAdmin()
        .then((res) => {
          logger.log(res);
          let provisioners = res.data.provisionerList.map((provisioner) => {
            return {
              value: provisioner.pid,
              display: provisioner.commonName,
            };
          });
          dispatch(adminActions.setProvisionerList(provisioners));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
};

export const initialSyncAsProvisionerAdminMiddleware = () => {
  return (dispatch, getState) => {
    let { currentPid } = getState().admin;
    let pid;
    if (currentPid && currentPid.value) {
      pid = currentPid.value;
    }
    if (pid && getState().auth.isAdmin) {
      if (getState().conflictCase.caseList.length === 0) {
        dispatch(conflictCaseActions.setIsLoading(true));
      }
      initialSyncAsProvisionerAdmin(pid)
        .then((res) => {
          logger.log("initial sync resolved");
          let mappedCases = res.data.cases.map((resObj) => {
            return {
              caseId: resObj.caseId,
              openedBy: resObj.pid,
              openedAgainst: resObj.receivingPid,
              numberTns: resObj.countTns,
              openedTs: resObj.openedTs,
              expiresTs: resObj.expiredTs,
              status: resObj.status,
              tns: resObj.tns,
              loas: resObj.loas,
              comments: resObj.activity,
              nnidToProvisionTo: resObj.nnidToProvisionTo,
              nnidType: resObj.nnidType,
            };
          });

          let mappedNotifications = res.data.notifications.map((axiosObj) => {
            return {
              notificationId: axiosObj.pid + axiosObj.createdTs,
              caseId: axiosObj.caseId,
              timestamp: axiosObj.createdTs,
              header: axiosObj.notificationType,
              read: axiosObj.read,
              text: axiosObj.text,
              primaryKey: axiosObj.primaryKey,
            };
          });

          dispatch(
            notificationActions.setNotificationList(mappedNotifications)
          );
          dispatch(notificationActions.setIsLoading(false));

          dispatch(conflictCaseActions.setIsLoading(false));
          dispatch(conflictCaseActions.setCaseList(mappedCases));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
};

export function fetchCaseAdminMiddleware(caseId) {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid
      ? getState().admin.currentPid.value
      : null;
    if (!getState().conflictCase.isLoading && pid) {
      logger.log("Inside of fetch case");
      dispatch(conflictCaseActions.setIsLoading(true));
      fetchCaseAxiosAdmin(pid, caseId)
        .then((res) => {
          let { data } = res;
          dispatch(
            conflictCaseActions.setCase({
              caseId: data.caseId,
              openedBy: data.pid,
              openedAgainst: data.receivingPid,
              numberTns: data.countTns,
              openedTs: data.openedTs,
              expiresTs: data.expiredTs,
              status: data.status,
              tns: data.tns,
              loas: data.loas,
              comments: data.activity,
              nnidToProvisionTo: data.nnidToProvisionTo,
              nnidType: data.nnidType,
            })
          );
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
}
