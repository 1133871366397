import { createContext, useState, useContext } from "react";
// import useNotifications from "../testing-data/useNotifications";

const GlobalContext = createContext();

/**
 *
 * @param {Component} child
 * @returns {ContextProvider}
 */
const SidebarContextProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const globalData = {
    setShowSidebar,
    showSidebar,
  };

  return (
    <GlobalContext.Provider value={globalData}>
      {children}
    </GlobalContext.Provider>
  );
};

const useSidebarContext = () => {
  let data = useContext(GlobalContext);
  return data;
};

export { useSidebarContext, SidebarContextProvider };
