import { caseOpened } from "../../utils/genericUtils";
import { useModalContext } from "../../context/ModalContext";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RejectTnsModalContent } from "./RejectTnsModalContent";
import { AcceptTnsModalContent } from "./AcceptTnsModalContent";
import { FaInfoCircle } from "react-icons/fa";
import { useRef } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";

const resultCodeToCssClass = {
  ACCEPTED: "accepted",
  ERROR_CHANGED_PROVISIONERS: "error error-changed-provisioners",
  ERROR_GENERIC: "error error-generic",
  REJECTED: "rejected",
};

/**
 * Component for Requested TNs section of a Singular Case
 *
 * @param {Object} currentCase Entire currentCase object
 * @returns {Component}
 */
const RequestedTns = ({ currentCase }) => {
  const { displayModal } = useModalContext();
  const pid = useSelector((state) => state.auth.pid);
  const disabled = currentCase.openedBy === pid || !caseOpened(currentCase);
  const isAdmin = useIsAdminAuthenticated()();

  const [selectedTns, setSelectedTns] = useState([]);
  const requestedTns = currentCase.tns;
  const selectAllChecked =
    selectedTns.length ===
      requestedTns
        .map((tnObj) => {
          if (!tnObj.responded) {
            return tnObj.tn;
          }
        })
        .filter((tnOrNull) => tnOrNull).length && caseOpened(currentCase);

  /**
   * If "Select All" is already checked, this will deselect all numbers. If "Select All" isn't checked, this will select all Tns.
   * Update selectedTns state.
   *
   * @param {Object: Event} e The event object created by the user click of "Select All" button.
   */
  const selectDeselectAllTns = (e) => {
    if (e.target.checked) {
      setSelectedTns(
        requestedTns
          .map((tnObj) => {
            if (!tnObj.responded) {
              return tnObj.tn;
            }
          })
          .filter((tnOrNull) => tnOrNull)
      );
    } else {
      setSelectedTns([]);
    }
  };

  /**
   * Select or deselect the number that was just clicked. Update selectedTns state.
   *
   * @param {Object: Event} e The event object created by the user click of a specific TN
   */
  const clickTn = (e) => {
    let tn = e.target.id;
    let tnIndex = selectedTns.findIndex((potentialTn) => tn === potentialTn);
    if (tnIndex === -1) {
      setSelectedTns((selectedTns) => [...selectedTns, tn]);
    } else {
      setSelectedTns((selectedTns) => {
        if (tnIndex < selectedTns.length && selectedTns[tnIndex] === tn) {
          //Catching weird bug where this function is ran twice
          selectedTns.splice(tnIndex, 1);
        }
        return [...selectedTns]; //For some reason, needed to destructure to render correctly
      });
    }
  };

  /**
   * Opens Accept TNs modal with AcceptTnsModalContent component, with validations
   */
  const openAcceptTnsModal = () => {
    if (pid === currentCase.openedBy) {
      displayModal(
        <p>You cannot accept TNs in a case you opened</p>,
        "Oops!",
        { width: "50%" },
        true
      );
    } else if (!caseOpened(currentCase)) {
      displayModal(
        <p>You cannot accept TNs in a closed case!</p>,
        "Oops!",
        { width: "50%" },
        true
      );
    } else {
      displayModal(
        <AcceptTnsModalContent
          tns={selectedTns}
          provisioner={currentCase.openedBy}
          caseId={currentCase.caseId}
          setSelectedTns={setSelectedTns}
        />,
        "Accept TNs",
        { width: "50%" },
        false
      );
    }
  };

  /**
   * Opens Reject TNs modal with RejectTnsModalContent component, with validations
   */
  const openRejectTnsModal = () => {
    if (pid === currentCase.openedBy) {
      displayModal(
        <p>You cannot reject TNs in a case you opened</p>,
        "Oops!",
        { width: "50%" },
        true
      );
    } else if (currentCase.status === "CLOSED") {
      displayModal(
        <p>You cannot reject TNs in a closed case!</p>,
        "Oops!",
        { width: "50%" },
        true
      );
    } else {
      displayModal(
        <RejectTnsModalContent
          tns={selectedTns}
          provisioner={currentCase.openedBy}
          caseId={currentCase.caseId}
          setSelectedTns={setSelectedTns}
        />,
        "Reject TNs",
        { width: "50%" },
        false
      );
    }
  };

  if (!requestedTns || requestedTns.length === 0) {
    return <div>There are no TNs associated with this case...</div>;
  }

  return (
    <div className="requested-header">
      <div className="requested-tns-header tn">
        <input
          type="checkbox"
          id="select-all"
          onChange={selectDeselectAllTns}
          disabled={disabled}
          checked={selectAllChecked}
        />
        <label htmlFor="select-all" className="fixed-width-text">
          {!selectAllChecked ? "Select All" : "Deselect All"}
        </label>
      </div>
      <div className="requested-tns">
        {requestedTns.map((tn) => {
          return (
            <div
              className={
                "tn " + (tn.responded ? resultCodeToCssClass[tn.result] : "")
              }
              key={tn.tn}
            >
              <input
                type="checkbox"
                id={tn.tn}
                onChange={(e) => {
                  if (!tn.responded) {
                    clickTn(e);
                  }
                }}
                disabled={tn.responded || disabled}
                checked={selectedTns.includes(tn.tn)}
              />
              <span className="tn-container">
                <label htmlFor={tn.tn} className="fixed-width-text">
                  {tn.tn}
                </label>
                {tn.result === "ERROR_CHANGED_PROVISIONERS" && (
                  <>
                    <Tooltip
                      title="Unfortunately, this TN has moved to a different PID. You
                        will need to open a new case"
                    >
                      <IconButton size="small">
                        <FaInfoCircle size={"16px"} />
                      </IconButton>
                    </Tooltip>
                    {/***/}
                  </>
                )}
                {tn.result === "ERROR_GENERIC" && (
                  <>
                    <Tooltip
                      title="While the provisioner accepted this TN, there was an
                      unexpected error in releasing it. Please reach out to
                      NetNumber for further assistance"
                    >
                      <IconButton>
                        <FaInfoCircle />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </span>
            </div>
          );
        })}
      </div>
      <div className="requested-tns-controls">
        {!isAdmin && (
          <>
            <Button
              className={disabled ? "disabled" : "success"}
              disabled={disabled}
              variant="contained"
              color="success"
              onClick={openAcceptTnsModal}
            >
              Accept
            </Button>
            <Button
              onClick={openRejectTnsModal}
              disabled={disabled}
              variant="contained"
              color="error"
              className={disabled ? "disabled" : "danger"}
            >
              Reject
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export { RequestedTns };
