import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { submitCommentMiddleware } from "../../middleware/conflictCaseMiddleware";
import { getTimeStringShort } from "../../utils/timeUtils";
import { Button } from "@mui/material";
import TextareaInput from "../../components/forms/TextareaInput";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";

/**
 * Component for Activity section of a Singular Case
 *
 * @param {list} comments List of comment objects to be displayed associated with the case.
 *
 * @returns {Component}
 */
const Activity = ({ comments, openedBy, caseId }) => {
  const pid = useSelector((state) => state.auth.pid);
  const textBoxRef = useRef(null);
  const dispatch = useDispatch();
  const isAdmin = useIsAdminAuthenticated()();

  /**
   * Adds a comment to the global case state and currentCase state
   *
   * @param {String} text value of comment text
   */
  const submitComment = (text) => {
    dispatch(submitCommentMiddleware(openedBy, caseId, text));
  };

  if (!comments || comments.length === 0) {
    return <div>There are no Comments associated with this case...</div>;
  }

  return (
    <div className="comments-container">
      {comments.map((comment) => {
        return (
          <div
            className={
              "comment " +
              (comment.commentPid === pid ? "yourself" : "them") +
              " " +
              (comment.systemComment ? "system" : "")
            }
            key={comment.commentTs}
          >
            <div className="comment-header">
              <div className="comment-by">
                {!comment.systemComment
                  ? `Provisioner: ${
                      comment.commentProvisionerName !== null //Temp, for older comments
                        ? comment.commentProvisionerName
                        : comment.commentPid
                    } | User: ${comment.commentBy} `
                  : "Case Information"}
              </div>
              <div className="comment-ts">
                {getTimeStringShort(comment.commentTs)}
              </div>
            </div>
            {comment.commentText}
          </div>
        );
      })}
      {!isAdmin && (
        <div className="comments-input">
          <TextareaInput
            sx={{
              minWidth: "100%",
            }}
            inputRef={textBoxRef}
          />
          <Button
            variant="outlined"
            onClick={() => {
              submitComment(textBoxRef.current.value);
              textBoxRef.current.value = "";
            }}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export { Activity };
