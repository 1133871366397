import {
  fetchNotificationsAxios,
  postReadAllNotificationsAxios,
  postReadUnreadNotificationAxios,
} from "../service/notificationsService";
import { handleRequestError } from "./middlewareUtils";
import { notificationActions } from "../store/notificationsSlice";
import logger from "../utils/logger";

function fetchNotificationsMiddleware() {
  return (dispatch, getState) => {
    if (!getState().notification.isLoading && getState().auth.pid) {
      dispatch(notificationActions.setIsLoading(true));
      fetchNotificationsAxios(getState().auth.pid)
        .then((res) => {
          let mappedResult = res.data.map((axiosObj) => {
            return {
              notificationId: axiosObj.pid + axiosObj.createdTs,
              caseId: axiosObj.caseId,
              timestamp: axiosObj.createdTs,
              header: axiosObj.notificationType,
              read: axiosObj.read,
              text: axiosObj.text,
              primaryKey: axiosObj.primaryKey,
            };
          });
          dispatch(notificationActions.setNotificationList(mappedResult));
          dispatch(notificationActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
}

function markAllNotificationsAsReadMiddleware() {
  return (dispatch, getState) => {
    let originalState = getState().notification.notificationList;

    dispatch(notificationActions.markAllAsRead());

    postReadAllNotificationsAxios()
      .then((res) => {
        logger.log(res.data);
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(notificationActions.setNotificationList(originalState)); //Rollback Example
      });
  };
}

function markNotificationAsReadUnreadMiddleware(createdTs, readUnread) {
  return (dispatch, getState) => {
    const originalState = getState().notification.notificationList;
    const pid = getState().auth.pid;

    dispatch(
      notificationActions.markAsReadUnread({
        notificationId: pid + createdTs,
        readUnread,
      })
    );

    postReadUnreadNotificationAxios(createdTs, readUnread)
      .then((res) => {
        logger.log(res.data);
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(notificationActions.setNotificationList(originalState));
      });
  };
}

export {
  fetchNotificationsMiddleware,
  markAllNotificationsAsReadMiddleware,
  markNotificationAsReadUnreadMiddleware,
};
