import { capitalize } from "../../utils/genericUtils";
import { getTimeStringShort } from "../../utils/timeUtils";

/**
 * The headers that are represented on the caseList page. If "accessor" is a string, it will access the corresponding value
 * of the case object. If the "accessor" is a method, that method will be invoked before displaying that column.
 *
 * @param {String} pid String of the users current pid. Needed to translate data containing you're pid to
 * the value "You" that will be displayed on the screen
 * @returns {List:Object}
 */
const getHeaders = (pid) => {
  return [
    {
      Header: "Case Id",
      accessor: (row) => row.caseId.substring(0, 6), // accessor is the "key" in the data
    },
    {
      Header: "CaseIdFullValue", //This column is HIDDEN! Used to access the caseId value inside of a row.
      accessor: "caseId", // accessor is the "key" in the data
    },
    {
      Header: "Status",
      accessor: (row) => capitalize(row.status),
    },
    {
      Header: "Opened By",
      accessor: (row) =>
        row.openedBy === pid ? "You" : capitalize(row.openedBy),
    },
    {
      Header: "Opened Against",
      accessor: (row) =>
        row.openedAgainst === pid ? "You" : capitalize(row.openedAgainst),
    },
    {
      Header: "# TNs",
      accessor: "numberTns",
    },
    {
      Header: "Opened On",
      accessor: (row) => row.openedTs,
    },
    {
      Header: "Expiration Date",
      accessor: (row) => row.expiresTs,
    },
  ];
};

export { getHeaders };
