const oidc_authority_url = process.env.REACT_APP_OIDC_AUTHORITY_URL;
const oidc_authority_client_id = process.env.REACT_APP_OIDC_AUTHORITY_CLIENT_ID;

export const OIDC_CONFIG = {
  authority: oidc_authority_url,
  client_id: oidc_authority_client_id,
  response_type: "code",
  scope: "openid id profile refresh_token",
  response_mode: "query",
  automaticSilentRenew: false, // was unable to get this working correctly...
  //accessTokenExpiringNotificationTimeInSeconds: 60,
  silentRequestTimeoutInSeconds: 5,
  redirect_uri: window.location.origin + "/callback",
  silent_redirect_uri: window.location.origin + "/silent",
  post_logout_redirect_uri: window.location.origin + "/logout",
  metadata: {
    issuer: oidc_authority_url,
    authorization_endpoint: oidc_authority_url + "/services/oauth2/authorize",
    userinfo_endpoint: oidc_authority_url + "/services/oauth2/userinfo",
    token_endpoint: oidc_authority_url + "/services/oauth2/token",
    end_session_endpoint: oidc_authority_url + "/services/auth/idp/oidc/logout",
    jwks_uri: oidc_authority_url + "/id/keys",
  },

  // metadata: {

  // issuer: "https://netnumbersecondorg--nngds.sandbox.my.salesforce.com",
  // authorization_endpoint:
  //   "https://netnumbersecondorg--nngds.sandbox.my.salesforce.com/services/oauth2/authorize",
  // userinfo_endpoint:
  //   "https://netnumbersecondorg--nngds.sandbox.my.salesforce.com/services/oauth2/userinfo",
  // token_endpoint:
  //   "https://netnumbersecondorg--nngds.sandbox.my.salesforce.com/services/oauth2/token",
  // end_session_endpoint:
  //   "https://netnumbersecondorg--nngds.sandbox.my.salesforce.com/services/auth/idp/oidc/logout",
  // jwks_uri:
  //   "https://netnumbersecondorg--nngds.sandbox.my.salesforce.com/id/keys",

  //   issuer: "https://netnumbersecondorg--nngds.sandbox.my.site.com",
  //   authorization_endpoint:
  //     "https://netnumbersecondorg--nngds.sandbox.my.site.com/services/oauth2/authorize",
  //   userinfo_endpoint:
  //     "https://netnumbersecondorg--nngds.sandbox.my.site.com/services/oauth2/userinfo",
  //   token_endpoint:
  //     "https://netnumbersecondorg--nngds.sandbox.my.site.com/services/oauth2/token",
  //   end_session_endpoint:
  //     "https://netnumbersecondorg--nngds.sandbox.my.site.com/services/auth/idp/oidc/logout",
  //   jwks_uri: "https://netnumbersecondorg--nngds.sandbox.my.site.com/id/keys",
  // },
};
//https://netnumbersecondorg.force.com/customers/login

// issuer: oidc_authority_url,
// authorization_endpoint: `${cognito_domain}/oauth2/authorize`,
// userinfo_endpoint: `${cognito_domain}/oauth2/userInfo`,
// token_endpoint: `${cognito_domain}/oauth2/token`,
// end_session_endpoint: `${cognito_domain}/logout?logout_uri=${encodeURIComponent(
//   window.location.origin + "/logout"
// )}&client_id=${oidc_authority_client_id}`,
// jwks_uri: `${oidc_authority_url}/.well-known/jwks.jsons`,
