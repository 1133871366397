import CasesList from "./pages/CasesList";
import NewCase from "./pages/NewCase";
import Notifications from "./pages/Notifications";
import SingularCase from "./pages/SingularCase";
import AdminCasesList from "./pages/CasesList";
import AdminNotifications from "./pages/Notifications";
import AdminSingularCase from "./pages/SingularCase";
import Error from "./pages/Error";
import { SidebarContextProvider } from "./context/SidebarContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { ModalContextProvider } from "./context/ModalContext";
import { Provider } from "react-redux";
import store from "./store";
import Login from "./pages/Login";
import Callback from "./pages/Callback";
import AuthProvider from "./auth/AuthProvider";
import InitialSync from "./auth/InitialSync";
import InitialAdminSync from "./auth/InitialSyncAdmin";
import userManager from "./auth/userServices";
import Logout from "./pages/Logout/Logout";
import { Helmet } from "react-helmet";
import SilentRefresh from "./pages/SilentRefresh";
import AutoSilentSignin from "./auth/AutoSilentSignin";
import SharedLayout from "./SharedLayout";

function App() {
  const HTML_TITLE = process.env.REACT_APP_HTML_TITLE;

  return (
    <>
      <Helmet>
        <title>{HTML_TITLE}</title>
      </Helmet>
      <Provider store={store}>
        <SidebarContextProvider>
          <ModalContextProvider>
            <AuthProvider userManager={userManager} store={store}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<SharedLayout store={store} />}>
                    <Route index element={<Login />} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="callback" element={<Callback />} />
                    <Route path="silent" element={<SilentRefresh />} />
                    <Route element={<AutoSilentSignin />}>
                      <Route element={<InitialSync></InitialSync>}>
                        <Route path="caselist" element={<CasesList />} />
                        <Route path="case/:caseId" element={<SingularCase />} />
                        <Route path="newcase" element={<NewCase />} />
                        <Route
                          path="notifications"
                          element={<Notifications />}
                        />
                        <Route path="error" element={<Error />} />
                        <Route
                          path="*"
                          element={
                            <Error
                              stateProp={{
                                statusCode: "404",
                                reason: "That page doesn't seem to exist",
                              }}
                            />
                          }
                        />
                      </Route>
                      <Route path="/admin">
                        <Route element={<InitialAdminSync></InitialAdminSync>}>
                          <Route path="caselist" element={<AdminCasesList />} />
                          <Route
                            path="case/:caseId"
                            element={<AdminSingularCase />}
                          />
                          <Route
                            path="notifications"
                            element={<AdminNotifications />}
                          />
                          <Route path="error" element={<Error />} />
                          <Route
                            path="*"
                            element={
                              <Error
                                stateProp={{
                                  statusCode: "404",
                                  reason: "That page doesn't seem to exist",
                                }}
                              />
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </AuthProvider>
          </ModalContextProvider>
        </SidebarContextProvider>
      </Provider>
    </>
  );
}

export default App;
