import { createSlice } from "@reduxjs/toolkit";
import {
  respondTns,
  submitComment,
  createNewCase,
  addNewFilter,
  removeFilter,
  closeUnfinishedCase,
  setIsLoading,
  setCaseList,
  testCommentRollback,
  setCase,
  setNewCaseResultsData,
  setNewCaseAsOpened,
  setNewCaseCurrentPage,
  setNnidList,
  setNewCaseNnid,
} from "../reducer/conflictCaseReducers";

const conflictCaseSlice = createSlice({
  name: "conflictCase",
  initialState: {
    caseList: [],
    isLoading: false,
    newCaseResultsData: [],
    newCaseCurrentPage: 0,
    filters: [],
    nnidList: [],
    newCaseNnid: null,
  },
  reducers: {
    respondTns,
    submitComment,
    createNewCase,
    removeFilter,
    setIsLoading,
    setCaseList,
    testCommentRollback,
    setCase,
    setNewCaseResultsData,
    setNewCaseAsOpened,
    setNewCaseCurrentPage,
    setNnidList,
    setNewCaseNnid,
    addNewFilter,
    closeUnfinishedCase,
  },
});

const conflictCaseActions = conflictCaseSlice.actions;

export { conflictCaseActions, conflictCaseSlice };
