import {
  fetchCaseAxios,
  fetchCasesAxios,
  postCreateCaseAxios,
  postAcceptTnsAxios,
  postRejectTnsAxiosWithAdditionalContext,
  postRejectTnsAxiosWithoutAdditionalContext,
  postSubmitCommentAxios,
  postAttemptProvisionTnsAxios,
  fetchNnidList,
  initialSyncAxios,
  closeUnfinishedCaseAxios,
} from "../service/conflictCaseService";
import { organizeResults } from "../utils/caseUtils";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import { notificationActions } from "../store/notificationsSlice";
import { authActions } from "../store/authSlice";
import { errorActions } from "../store/errorSlice";
import { handleRequestError } from "./middlewareUtils";
import logger from "../utils/logger";

function fetchCasesMiddleware() {
  return (dispatch, getState) => {
    logger.log("fetchCases Called");
    if (!getState().conflictCase.isLoading && getState().auth.pid !== null) {
      // Fixed bug that something was calling this very quickly
      logger.log("inside of fetchCases");
      if (getState().conflictCase.caseList.length === 0) {
        dispatch(conflictCaseActions.setIsLoading(true));
      }

      fetchCasesAxios()
        .then((res) => {
          let mappedData = res.data.cases.map((resObj) => {
            return {
              caseId: resObj.caseId,
              openedBy: resObj.pid,
              openedAgainst: resObj.receivingPid,
              numberTns: resObj.countTns,
              openedTs: resObj.openedTs,
              expiresTs: resObj.expiredTs,
              status: resObj.status,
              tns: resObj.tns,
              loas: resObj.loas,
              comments: resObj.activity,
              nnidToProvisionTo: resObj.nnidToProvisionTo,
              nnidType: resObj.nnidType,
            };
          });
          dispatch(conflictCaseActions.setCaseList(mappedData));
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
}

function closeUnfinishedCaseMiddleware(caseId) {
  return (dispatch, getState) => {
    closeUnfinishedCaseAxios(caseId)
      .then((res) => {
        dispatch(conflictCaseActions.closeUnfinishedCase(caseId));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
      });
  };
}

function initialSyncMiddleware() {
  return (dispatch, getState) => {
    logger.log("initial sync called");
    if (getState().auth.pid !== null) {
      if (getState().conflictCase.caseList.length === 0) {
        dispatch(conflictCaseActions.setIsLoading(true));
      }
      initialSyncAxios()
        .then((res) => {
          logger.log("initial sync resolved");
          let mappedCases = res.data.cases.map((resObj) => {
            return {
              caseId: resObj.caseId,
              openedBy: resObj.pid,
              openedAgainst: resObj.receivingPid,
              numberTns: resObj.countTns,
              openedTs: resObj.openedTs,
              expiresTs: resObj.expiredTs,
              status: resObj.status,
              tns: resObj.tns,
              loas: resObj.loas,
              comments: resObj.activity,
              nnidToProvisionTo: resObj.nnidToProvisionTo,
              nnidType: resObj.nnidType,
            };
          });

          let nnids = res.data.nnids.map((nnidObj) => {
            return {
              display:
                `${nnidObj.nnid} / ${nnidObj.nnidName} ` +
                (nnidObj.authPolicy === "NP_LOA"
                  ? "(NP LOA)"
                  : nnidObj.authPolicy === "SUBSCRIBER_LOA"
                  ? "(SUB LOA)"
                  : "(TF LOA)"),
              value: nnidObj.nnid,
              type: nnidObj.authPolicy,
            };
          });

          dispatch(conflictCaseActions.setNnidList(nnids));

          let mappedNotifications = res.data.notifications.map((axiosObj) => {
            return {
              notificationId: axiosObj.pid + axiosObj.createdTs,
              caseId: axiosObj.caseId,
              timestamp: axiosObj.createdTs,
              header: axiosObj.notificationType,
              read: axiosObj.read,
              text: axiosObj.text,
              primaryKey: axiosObj.primaryKey,
            };
          });

          dispatch(
            notificationActions.setNotificationList(mappedNotifications)
          );

          let provisionerCommonName = res.data.provisionerCommonName;

          dispatch(authActions.setProvisionerCommonName(provisionerCommonName));

          dispatch(conflictCaseActions.setCaseList(mappedCases));

          dispatch(notificationActions.setIsLoading(false));
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
}

function fetchCaseMiddleware(caseId) {
  return (dispatch, getState) => {
    logger.log("fetch case called");
    if (!getState().conflictCase.isLoading && getState().auth.pid) {
      logger.log("Inside of fetch case");
      dispatch(conflictCaseActions.setIsLoading(true));
      fetchCaseAxios(caseId)
        .then((res) => {
          let data = res.data.conflictCase;
          dispatch(
            conflictCaseActions.setCase({
              caseId: data.caseId,
              openedBy: data.pid,
              openedAgainst: data.receivingPid,
              numberTns: data.countTns,
              openedTs: data.openedTs,
              expiresTs: data.expiredTs,
              status: data.status,
              tns: data.tns,
              loas: data.loas,
              comments: data.activity,
              nnidToProvisionTo: data.nnidToProvisionTo,
              nnidType: data.nnidType,
            })
          );
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
}

function fetchNnidListMiddleware() {
  logger.log("fetch NNID list called");
  return (dispatch, getState) => {
    const pid = getState().auth.pid;
    fetchNnidList(pid)
      .then((res) => {
        dispatch(conflictCaseActions.setNnidList(res.data.nnids));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
      });
  };
}

function submitCommentMiddleware(openingPid, caseId, commentText) {
  //TODO: implement rollbacking
  return (dispatch, getState) => {
    let originalState = {
      ...getState().conflictCase.caseList.find((singularCase) => {
        return singularCase.caseId === caseId;
      }),
    };
    let { displayName, pid, email, provisionerCommonName } = getState().auth;

    dispatch(
      conflictCaseActions.submitComment({
        caseId,
        comment: {
          commentBy: displayName,
          commentPid: pid,
          commentTs: new Date().getTime(),
          systemComment: false,
          commentProvisionerName: provisionerCommonName,
          commentText,
        },
      })
    );
    postSubmitCommentAxios(openingPid, caseId, commentText)
      .then((res) => {
        logger.log(res);
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setCase(originalState));
      });
  };
}

function submitAcceptTnsMiddleware(openingPid, caseId, tns) {
  return (dispatch, getState) => {
    let { pid, email } = getState().auth;
    let currentState = getState().conflictCase.caseList.find((singularCase) => {
      return singularCase.caseId === caseId;
    });
    dispatch(conflictCaseActions.setIsLoading(true));
    postAcceptTnsAxios(openingPid, caseId, tns)
      .then((res) => {
        logger.log(res);
        dispatch(
          conflictCaseActions.respondTns({
            caseId,
            tns,
            acceptOrReject: true,
            newSystemCommentText: res.data.newSystemCommentText,
            newSystemCommentTs: res.data.newSystemCommentTs,
          })
        );

        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setCase(currentState));
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

function submitRejectTnsMiddleware(
  openingPid,
  caseId,
  tns,
  reason,
  additionalContext
) {
  return (dispatch, getState) => {
    let currentState = getState().conflictCase.caseList.find((singularCase) => {
      return singularCase.caseId === caseId;
    });
    currentState = { ...currentState }; //Recreate as new object, not reference
    dispatch(conflictCaseActions.setIsLoading(true));

    let postRejectTnsPromise;

    if (additionalContext === null || additionalContext === "") {
      postRejectTnsPromise = postRejectTnsAxiosWithoutAdditionalContext(
        openingPid,
        caseId,
        tns,
        reason
      );
    } else {
      postRejectTnsPromise = postRejectTnsAxiosWithAdditionalContext(
        openingPid,
        caseId,
        tns,
        reason,
        additionalContext
      );
    }

    postRejectTnsPromise
      .then((res) => {
        dispatch(
          conflictCaseActions.respondTns({
            caseId,
            tns,
            acceptOrReject: false,
            newSystemCommentText: res.data.newSystemCommentText,
            newSystemCommentTs: res.data.newSystemCommentTs,
          })
        );
        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setCase(currentState));
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

function submitNewCaseMiddleware(openedAgainst, tns, loas) {
  return (dispatch, getState) => {
    const { pid } = getState().auth;
    const nnid = getState().conflictCase.newCaseNnid.value;
    dispatch(conflictCaseActions.setIsLoading(true));

    postCreateCaseAxios(openedAgainst, tns, loas, nnid)
      .then((res) => {
        logger.log(res);
        const { caseId, openedTs, expiresTs } = res.data;
        dispatch(
          conflictCaseActions.createNewCase({
            openedBy: pid,
            openedAgainst: openedAgainst,
            tns: tns,
            loas: loas,
            caseId,
            openedTs,
            expiresTs,
          })
        );
        dispatch(conflictCaseActions.setNewCaseAsOpened(openedAgainst));

        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

function attemptProvisionNewCaseTnsMiddleware(tns) {
  return (dispatch, getState) => {
    let nnid = getState().conflictCase.newCaseNnid;
    dispatch(conflictCaseActions.setIsLoading(true));
    postAttemptProvisionTnsAxios(nnid.value, tns)
      .then((res) => {
        dispatch(
          conflictCaseActions.setNewCaseResultsData(organizeResults(res.data))
        );
        dispatch(conflictCaseActions.setNewCaseCurrentPage(1));
        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

export {
  fetchCaseMiddleware,
  fetchCasesMiddleware,
  submitCommentMiddleware,
  submitAcceptTnsMiddleware,
  submitRejectTnsMiddleware,
  submitNewCaseMiddleware,
  attemptProvisionNewCaseTnsMiddleware,
  fetchNnidListMiddleware,
  initialSyncMiddleware,
  closeUnfinishedCaseMiddleware,
};
