/**
 *
 * @param {Object} action.payload
 * {
 *    caseId: [str] caseId UUID
 *    tns: [list str] string list of tns being updated
 *    approvedOrRejected: [bool] true if tns are being accepted, false if rejected
 * }
 */
export function respondTns(state, action) {
  let {
    caseId,
    tns,
    acceptOrReject,
    newSystemCommentText,
    newSystemCommentTs,
  } = action.payload;
  let caseList = state.caseList;

  let currentCase = caseList.find((singularCase) => {
    return singularCase.caseId === caseId;
  });

  let numberIndexMapping = {};
  tns.forEach((updatedTn) => {
    let index = currentCase.tns.findIndex((tnObj) => tnObj.tn === updatedTn);
    if (index !== -1) {
      numberIndexMapping[updatedTn] = index;
    }
  });

  Object.keys(numberIndexMapping).forEach((tn) => {
    currentCase.tns[numberIndexMapping[tn]] = {
      tn: tn,
      responded: true,
      result: acceptOrReject ? "ACCEPTED" : "REJECTED",
    };
  });

  let countResponded = 0;
  currentCase.tns.forEach((tnObj) => {
    if (tnObj.responded) {
      countResponded++;
    }
  });

  currentCase.comments.push({
    commentBy: "Case Information",
    commentPid: "system",
    commentTs: newSystemCommentTs,
    commentText: newSystemCommentText,
    systemComment: true,
  });

  if (countResponded === currentCase.tns.length) {
    currentCase.status = "CLOSED";
  } else {
    currentCase.status = "IN_PROGRESS";
  }
}

/**
 *
 * @param {Object} action.payload
 * {
 *    caseId: [str] caseId UUID
 *    comment: [obj] the comment object in the following form:
 *    {
 *        commentBy: string (displayName),
          commentPid: string (pid),
          commentTs: int,
          systemComment: false,
          commentText: string (comment text),
 *    } 
 *
 * }
 */
export function submitComment(state, action) {
  let { caseId, comment } = action.payload;

  let currentCase = state.caseList.find((singularCase) => {
    return singularCase.caseId === caseId;
  });

  if (!currentCase.comments) {
    currentCase.comments = [];
  }
  currentCase.comments.push(comment);
}

export function closeUnfinishedCase(state, action) {
  let caseId = action.payload;

  let currentCase = state.caseList.find((singularCase) => {
    return singularCase.caseId === caseId;
  });

  currentCase.status = "CLOSED";
}

/**
 *
 * @param {Object} action.payload
 * {
 *    openedBy: [str] your pid
 *    openedAgainst: [str] pid to be opened against
 *    tns: [list str] tns for the case
 *    loas: [list str] filenames of the loas for the case
 * }
 */
export function createNewCase(state, action) {
  let { openedBy, openedAgainst, tns, loas, caseId, openedTs, expiresTs } =
    action.payload;

  state.caseList = [
    {
      caseId,
      openedBy: openedBy,
      openedAgainst: openedAgainst,
      openedTs: openedTs,
      numberTns: tns.length,
      expiresTs: expiresTs,
      status: "Open",
      tns: tns.map((tn) => {
        return {
          tn,
          responded: false,
          approved: false,
        };
      }),
      loas: loas.map((loa) => {
        return {
          displayName: loa,
          fileId: "dog1.jpg",
        };
      }),
      comments: [],
    },
    ...state.caseList,
  ];
}

export function addNewFilter(state, action) {
  let filterObj = action.payload;
  filterObj.id = new Date().getTime();

  state.filters.push(filterObj);
}

export function removeFilter(state, action) {
  let id = action.payload;

  state.filters = state.filters.filter((filter) => filter.id !== id);
}

export function setIsLoading(state, action) {
  state.isLoading = action.payload;
}
export function setCaseList(state, action) {
  state.caseList = action.payload;
}
export function testCommentRollback(state, action) {
  let caseId = action.payload;
  let singularCase = state.caseList.find(
    (singularCase) => singularCase.caseId === caseId
  );

  if (singularCase) {
    singularCase.comments.pop();
  }
}
export function setCase(state, action) {
  let caseId = action.payload.caseId;
  let caseIndex = state.caseList.findIndex(
    (singularCase) => singularCase.caseId === caseId
  );

  if (!state.caseList[caseIndex]) {
    state.caseList.push(action.payload);
  } else {
    state.caseList[caseIndex] = action.payload;
  }
}
export function setNewCaseResultsData(state, action) {
  state.newCaseResultsData = action.payload;
}
export function setNewCaseAsOpened(state, action) {
  const pid = action.payload;
  state.newCaseResultsData.error[pid].opened = true;
}
export function setNewCaseCurrentPage(state, action) {
  state.newCaseCurrentPage = action.payload;
}
export function setNnidList(state, action) {
  state.nnidList = action.payload;
}
export function setNewCaseNnid(state, action) {
  state.newCaseNnid = action.payload;
}
