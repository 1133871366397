/**
 *
 * @param resultList List of Objects,
 * [
 * {
 *  tn: "String version of tn"
 *  result: "error/provisioned/unable"
 *  (if error) -> pid : "string of pid"
 * }
 * ]
 * @returns
 * {
 *  provisioned: list of tns
 *  error: {
 *    pid1: { opened: boolean, tns: list of tns }
 *    pid2: { opened: boolean, tns: list of tns }
 *  }
 *  unable: {
 *    (if exists) nploa: [list of tns]
 *    (if exists) tollfree: [list of tns]
 *    (if exists) mobile: [list of tns]
 *  }
 * }
 */

const organizeResults = (resultList) => {
  let organizedResults = {
    provisioned: [],
    error: {},
    unable: {},
  };

  resultList.forEach((result) => {
    if (result.provisionResult === "PROVISIONED") {
      organizedResults.provisioned.push(result.tn);
    }
    if (result.provisionResult === "ERROR") {
      if (!organizedResults.error[result.errorPid]) {
        organizedResults.error[result.errorPid] = {
          opened: false,
          tns: [],
        };
      }
      organizedResults.error[result.errorPid].tns.push(result.tn);
    }
    if (result.provisionResult === "UNABLE") {
      if (!organizedResults.unable[result.unableReason]) {
        organizedResults.unable[result.unableReason] = [];
      }
      organizedResults.unable[result.unableReason].push(result.tn);
    }
  });

  return organizedResults;
};

export { organizeResults };
