import axios from "./axios";
import {
  BASE_URL,
  GET_CASE_ENDPOINT,
  GET_CASES_ENDPOINT,
  POST_CREATE_CASE_ENDPOINT,
  POST_ACCEPT_TNS_ENDPOINT,
  POST_REJECT_TNS_ENDPOINT,
  POST_SUBMIT_COMMENT_ENDPOINT,
  ATTEMPT_PROVISION_TNS_ENDPOINT,
  GET_NNID_LIST_ENDPOINT,
  INITIAL_SYNC_ENDPOINT,
  CLOSE_UNFINISHED_CASE_ENDPOINT,
} from "./serviceConfiguration";

function fetchCasesAxios() {
  return axios.get(BASE_URL + GET_CASES_ENDPOINT);
}

function closeUnfinishedCaseAxios(caseId) {
  return axios.post(BASE_URL + CLOSE_UNFINISHED_CASE_ENDPOINT, {
    caseId: caseId,
  });
}

function fetchCaseAxios(caseId) {
  return axios.get(BASE_URL + GET_CASE_ENDPOINT + `/${caseId}`);
}

function fetchNnidList() {
  return axios.get(BASE_URL + GET_NNID_LIST_ENDPOINT);
}

function initialSyncAxios() {
  return axios.get(BASE_URL + INITIAL_SYNC_ENDPOINT);
}

function postCreateCaseAxios(receivingPid, tns, loas, nnid) {
  return axios.post(BASE_URL + POST_CREATE_CASE_ENDPOINT, {
    receivingPid: receivingPid,
    tns: tns,
    loas: loas,
    caseNnid: nnid,
  });
}

function postAcceptTnsAxios(openingPid, caseId, tns) {
  return axios.post(BASE_URL + POST_ACCEPT_TNS_ENDPOINT, {
    openingPid: openingPid,
    caseId: caseId,
    tns: tns,
  });
}

function postRejectTnsAxiosWithoutAdditionalContext(
  openingPid,
  caseId,
  tns,
  reason
) {
  return axios.post(BASE_URL + POST_REJECT_TNS_ENDPOINT, {
    openingPid: openingPid,
    caseId: caseId,
    tns: tns,
    reason: reason,
  });
}

function postRejectTnsAxiosWithAdditionalContext(
  openingPid,
  caseId,
  tns,
  reason,
  additionalContext
) {
  return axios.post(BASE_URL + POST_REJECT_TNS_ENDPOINT, {
    openingPid: openingPid,
    caseId: caseId,
    tns: tns,
    reason: reason,
    additionalContext: additionalContext,
  });
}

function postSubmitCommentAxios(openingPid, caseId, commentText) {
  return axios.post(BASE_URL + POST_SUBMIT_COMMENT_ENDPOINT, {
    pid: openingPid,
    caseId: caseId,
    commentText: commentText,
  });
}

function postAttemptProvisionTnsAxios(nnid, tns) {
  return axios.post(BASE_URL + ATTEMPT_PROVISION_TNS_ENDPOINT, {
    nnid: nnid,
    tns: tns,
  });
}

export function downloadFileAxios(caseId, fileName) {
  return axios.get(BASE_URL + `${caseId}/${fileName}`, {
    responseType: "blob",
  });
}

export {
  fetchCaseAxios,
  fetchCasesAxios,
  postCreateCaseAxios,
  postAcceptTnsAxios,
  postRejectTnsAxiosWithAdditionalContext,
  postRejectTnsAxiosWithoutAdditionalContext,
  postSubmitCommentAxios,
  postAttemptProvisionTnsAxios,
  fetchNnidList,
  initialSyncAxios,
  closeUnfinishedCaseAxios,
};
