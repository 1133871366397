import { signinRedirect, attemptSilentSignin } from "../../auth/userServices";
import { FaSalesforce } from "react-icons/fa";
import "./Login.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../store";
import userManager from "../../auth/userServices";
import { loadUserFromStorage } from "../../auth/userServices";
import logger from "../../utils/logger";

const Login = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <div className="login-container">
        <div className="login-header">
          <h2>Net Number OSR Portal</h2>
          <p>
            Welcome to the NNSR Conflict Resolution Portal! Please login through
            your Net Number account
          </p>
        </div>
        <div className="line"></div>
        <button
          className="login-button"
          onClick={() => {
            signinRedirect()
              .then((res) => {
                logger.log(res);
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <FaSalesforce size={"4rem"} color="white" />
          <h1 style={{ color: "white" }}>Login</h1>
        </button>
      </div>
    </>
  );
};

export default Login;
