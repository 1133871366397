import { useSelector } from "react-redux";

const useIsUserAuthenticated = () => {
  const { pid, email } = useSelector((state) => state.auth);

  return () => {
    return (
      pid !== null && pid.trim() !== "" && email !== null && email.trim() !== ""
    );
  };
};

export default useIsUserAuthenticated;
