import { filterOutDuplicates } from "../../utils/listUtils";
import { capitalize } from "../../utils/genericUtils";

/**
 * Generates the different dropdown options for the filter modal. Will only return unique values
 * when necessary.
 *
 * @param {List: Objects} data list of case objects.
 * @param {String} pid String representation of the current users pid. Needed for "You" options on certain
 * value dropdown selections.
 * @returns
 */
const parseDropdownDataOptions = (data, pid) => {
  if (!data) {
    data = [];
  }

  let uniqueProvisionersBesidesYou = filterOutDuplicates(
    data,
    (singularCase) => {
      if (singularCase.openedBy !== pid) {
        return singularCase.openedBy;
      }

      if (singularCase.openedAgainst !== pid) {
        return singularCase.openedAgainst;
      }
    },
    (setObj) => {
      return {
        value: setObj,
        display: capitalize(setObj),
      };
    }
  );

  let dataOptions = {
    caseId: data.map((singularCase) => {
      return {
        value: singularCase.caseId,
        display: singularCase.caseId.substring(0, 6),
      };
    }),
    status: [
      {
        value: "OPENED",
        display: "Opened",
      },
      {
        value: "IN_PROGRESS",
        display: "In Progress",
      },
      {
        value: "CLOSED",
        display: "Closed",
      },
    ],
    openedBy: [
      {
        value: pid,
        display: "You",
      },
      ...uniqueProvisionersBesidesYou,
    ],
    openedAgainst: [
      {
        value: pid,
        display: "You",
      },
      ...uniqueProvisionersBesidesYou,
    ],
  };

  return dataOptions;
};

export { parseDropdownDataOptions };
