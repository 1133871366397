export function markAsReadUnread(state, action) {
  let { notificationId, readUnread } = action.payload;
  let { notificationList } = state;
  let index = notificationList.findIndex(
    (notification) => notification.notificationId === notificationId
  );
  if (index > -1 && index < notificationList.length) {
    notificationList[index].read = readUnread;
  }
}

export function markAllAsRead(state) {
  let { notificationList } = state;
  state.notificationList = notificationList.map((notification) => {
    return {
      ...notification,
      read: true,
    };
  });
}

export function setIsLoading(state, action) {
  state.isLoading = action.payload;
}
export function setNotificationList(state, action) {
  state.notificationList = action.payload;
}
